@import "../../../../assets/scss/variables.scss";

.VerificationOtpMain {
  width: 100%;
  float: left;
  .upperContent {
    .description {
      margin-bottom: 16px;
      color: $TXTWHITE;
      font-family: $OPENSANS;
    }
  }
  &.disable {
    pointer-events: none;
  }
  
  .signinLeft {
    height: 100%;
    background-color: $BGSECONDARY;
    display: flex;
    align-items: center;
    justify-content: center;

    .centerContent {

      margin: 0 auto;
      .logo {
        max-width: 100%;
        height: auto;
        display: block;
        margin-bottom: 26px;
      }
      h1 {
        font-family: $MONTSERRAT;
        color: $TXTWHITE;
        font-size: $FONT48;
        line-height: 59px;
        span {
          font-family: $MONTSERRAT;
          font-size: $FONT48;
          padding-left: 8px;
        }
      }
    }
  }

  .signinRight {
    width: 50%;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: $BGPRIMARY_ONE;
    .formGroupField{
      justify-content: space-between;
      display: flex;
      width: 100%;
      input{
        outline: none;
        font-size: 16px;
        color: $TXTPRIMARY_ONE;
        font-family: $OPENSANS;
      }
    }
      .formGroupField > div{
        width: 100%;
        justify-content: space-between;
        display: flex;
        margin-top: 20px;
        margin-bottom: 20px;
      }
    form {
      width: 335px;
      .submitBtn {
        .MuiButtonBase-root {
          margin-bottom: 4px;
        }
      }
      .backLink {
        text-align: center;
        margin-top: 32px;
        color: $TXTWHITE;
        font-family: $OPENSANS;
        display: flex;
        align-items: center;
        justify-content: center;
        a.MuiLink-root {
          display: inline-block;
          color: $TXTSECONDARY;
          text-decoration: none;
          font-family: $OPENSANS;
          font-size: $FONT16;
          font-weight: 600;
        }
        .redirectLinkText{
          display: flex;
          align-items: center;
          padding: 0px;
          color:$TXTSECONDARY;
          font-size: $FONT16;
          font-family: $OPENSANS;
          font-weight: 600;
          text-transform: capitalize;
        }
        .timerTextStyle{
          display: flex;
          align-items: center;
          padding: 0px;
          color:$TXTSECONDARY;
          font-size: $FONT16;
          font-family: $OPENSANS;
          font-weight: 600;
          margin-left: 5px;
        }
      }
      .errorMsg {
        &.show {
          color: $TXTYELLOW;
        }
      }
    }
    .rememberField {
      margin-bottom: 18px;
    }

    .upperContent {
      width: 335px;
      margin-top: 55px;
      .title {
        color: $TXTWHITE;
        font-size: $FONT20;
        font-family: $MONTSERRAT;
        font-weight: 800;
        margin-bottom: 19px;
      }
      .subTitle {
        color: $TXTBLACK;
        font-size: $FONT16;
        font-family: $MONTSERRAT;
        margin-bottom: 32px;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .VerificationOtpMain {
    & > div {
      flex-grow: 1;
    }
    display: flex;
    flex-direction: column;
    .signinLeft {
      width: 100%;
      .centerContent {
        .logo {
          max-width: 194px;
          height: auto;
        }
        h1 {
          font-size: $FONT30;
          line-height: 38px;
          span {
            font-size: $FONT30;
            line-height: 38px;
          }
        }
      }
    }
    .signinRight {
      width: 100%;
      max-width: 100%;
      .upperContent {
        max-width: 302px;
        
      }
      form {
        max-width: 302px;
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .VerificationOtpMain {
    flex-direction: column;
    .signinLeft {
      width: 100%;
      max-width: 100%;
      flex-basis: 30%;
      height: 30vh;
      .centerContent {
        .logo {
          max-width: 194px;
          height: 100%;
          margin-bottom: 0;
        }
        h1 {
          font-size: $FONT30;
          line-height: 38px;
          span {
            font-size: $FONT30;
            line-height: 38px;
          }
        }
      }
    }
    .signinRight {
      width: 100%;
      max-width: 100%;
      flex-basis: 70%;
      height: 70vh !important;
      .upperContent {
        max-width: 302px;

        
      }
      form {
        max-width: 302px;
      }
    }
  }
}
@media screen and (max-width: 1024px) and (orientation: landscape) {
  .VerificationOtpMain {
    display: block;
    .signinLeft {
      
      .centerContent {

        .logo {
          max-width: 194px;
          height: auto;
        }
        h1 {
          font-size: $FONT30;
          line-height: 38px;
          span {
            font-size: $FONT30;
            line-height: 38px;
          }
        }
      }
    }
    .signinRight {
      
      .upperContent {
        max-width: 302px;
        
      }
      form {
        max-width: 302px;
        .submitBtn {
          .customBtn {
            font-size: $FONT14;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1025px) and (max-width: 1279px) {
  .VerificationOtpMain {
    .signinLeft {
      .centerContent {
        .logo {
          max-width: 194px;
          height: 100%;
          width: 100%;
        }
        h1 {
          font-size: $FONT30;
          line-height: 38px;
          span {
            font-size: $FONT30;
            line-height: 38px;
          }
        }
      }
    }
    .signinRight {
      width: 50%;
      .upperContent {
        max-width: 302px;

        
      }
      form {
        max-width: 302px;
        .submitBtn {
          .customBtn{
            font-size: $FONT14;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1280px) and (max-width: 1439px) {
  .VerificationOtpMain {
    .signinLeft {
      width: 50%;
    }
    .signinRight {
      width: 50%;
      .upperContent {
        max-width: 335px;
        
      }
      form {
        max-width: 335px;
        .submitBtn {
          .customBtn{
            font-size: $FONT14;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1440px) and (max-width: 1919px) {
  .VerificationOtpMain {
    .signinRight {
      .upperContent {
        max-width: 335px;
        
      }
      form {
        max-width: 335px;
        .submitBtn {
          .customBtn{
            font-size: $FONT14;
          }
        }
      }
    }
  }
}





