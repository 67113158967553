@import "../../assets/scss/variables.scss";
.customRadioButton{
.Mui-checked{
	position: relative;
	path{
		display: none;
	}
	&::before{
		content: '\e93e';
		position: absolute;
		font-size: 18px;
		font-family: 'icomoon';
		color: $TXTPRIMARY_ONE;
	}
}
}
.PrivateSwitchBase-input {
	width: 14px;
	height: 14px;
	top: 3px;
	left: 3px;
}
.customRadioButton{
	background: $BGWHITE;
	border-radius: 10px;
	width: 100%;
	display: flex;
	align-items: center;
	padding: 14px 16px;
	justify-content: space-between;
	.MuiTypography-root {
		font-weight: 400;
		font-size: 14px;
		line-height: 21px;
		color: $TXTPRIMARY_ONE;
	}
	.MuiButtonBase-root{
		padding: 0px;
			.MuiSvgIcon-root{
				width: 16px;
				height: 16px;
				// border: 1px solid #C0C6CC;
				border-radius: 50%;
				fill: #C0C6CC;
			}
	}
}