@import "../../assets/scss/variables.scss";

.brandTitle {
  color: $BGPRIMARY_ONE;
  font-weight: 600;
  font-family: $OPENSANS;
  font-size: $FONT16;
  padding-bottom: 8px;
}

.demoBox {
  width: 54px;
  height: 30px;
  border: 1px solid black;
  border-radius: 5px;
}

.selectedColorPalette {
  max-width: 247px;
  min-width: 247px;
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;

  // .selectColorBox {
  //   width: 54px;
  //   height: 30px;
  //   border-radius: 4px;
  //   margin-right: 3px;
  //   margin-bottom: 3px;
  // }
}

.editColorBoxes {
  display: flex;
  margin-bottom: 10px;
}

.bgColor {
  width: 54px;
  height: 30px;
  border-radius: 5px;
}

.colorPalette {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
}

.colorOption {
  width: 54px;
  height: 30px;
  box-sizing: 6px;
}

.colorBox {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  margin-right: 3px;
  margin-bottom: 3px;
  cursor: pointer;
  position: relative;
}

.selectedBoxColor {
  border: 2px solid #333;
}

.removeColor {
  position: absolute;
  top: 2px;
  right: 2px;
  font-size: 12px;
  color: #fff;
  background-color: #333;
  padding: 2px 4px;
  border-radius: 50%;
  cursor: pointer;
}

.selectedColors {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 19px;
}

.selectedColorBox {
  width: 30px;
  height: 30px;
  margin-right: 3px;
  margin-bottom: 3px;
  border-radius: 4px;
}

.mainRow {
  // width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .leftDropdown {
    width: 49%;
  }

  .rightDropdown {
    width: 49%;
  }
}

.manageProducts {
  .MuiContainer-root {
    max-width: 1295px;
    padding: 0 15px;

    .MuiTabPanel-root {
      padding-left: 2px !important;
      padding-right: 2px !important;
      padding-bottom: 0px !important;
    }

    .cardWrap.MuiPaper-root {
      img {
        height: 115px;
        object-fit: cover;
      }
    }
  }

  .topbarWrap {
    justify-content: space-between;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 16px;

    button.MuiButton-text {
      background: $TXTWHITE;
      border: 1px solid $BGSECONDARY;
      border-radius: 10px;
      font-weight: 400;
      font-size: $FONT14;
      line-height: 1.5;
      font-family: $OPENSANS;
      text-transform: initial;
      padding: 8.5px 16px;
      height: 40px;
      color: $TXTPRIMARY_ONE;

      .icon-plus-11 {
        font-size: $FONT20;
        color: $TXTSECONDARY;
        margin-right: 6px;
      }
    }
  }

  .brandList {
    padding-left: 8px;
    padding-right: 8px;
    margin-top: 0px;

    .cardWrap.MuiPaper-root {
      margin: 0 8px 16px;
      width: calc(25% - 16px);

      .MuiCardActions-root {
        padding: 0;
      }
    }
  }

  .productList {
    padding-left: 8px;
    padding-right: 8px;
    margin-top: 0px;
    gap: 0px;

    .cardWrap.MuiPaper-root {
      margin: 0 8px 16px;
      width: calc(33.333% - 16px);

      .cardContent {
        max-width: 230px;
      }

      .rightWrap {
        width: calc(100% - 130px);
      }
    }
  }
}

.MuiTabs-indicator {
  display: none;
}

.MuiFormControl-root {
  .MuiSelect-icon {
    display: none;
  }

  .icon-chevron-down {
    position: absolute;
    right: 16px;
    top: 10px;
    font-size: $FONT20;
    color: $TXTPRIMARY_ONE;
    pointer-events: none;
  }
}

.addModalWrap.MuiBox-root {
  max-width: 550px;
  width: 100%;
  background: $BGPRIMARY_TWO;
  border-radius: 8px;
  padding: 20px;
  border: none;

  h5 {
    font-family: $OPENSANS;
    font-style: normal;
    font-weight: 600;
    font-size: $FONT16;
    line-height: 1.5;
    position: relative;
    margin-bottom: 24px;
    color: $TXTPRIMARY_ONE;

    span {
      position: absolute;
      right: 0;
      font-size: 24px;
      line-height: 1;
      top: 0;
      cursor: pointer;
    }
  }

  .imagesWrap {
    justify-content: space-between;
    margin-bottom: 16px;
    margin-left: -7px;
    margin-right: -7px;

    .image {
      border: 1px dashed $BORDER_GREY;
      height: 115px;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      cursor: pointer;

      .icon-thumbnail {
        font-size: 38px;
        color: $TXTOFFWHITE;
      }

      .uploadText {
        color: $TXTPRIMARY_ONE;
        background-color: rgba(192, 196, 204, 0.3);
        border-radius: 5px;
        font-family: $OPENSANS;
        font-size: $FONT12;
        line-height: 24px;
        margin-top: 11px;
        width: 56px;
        text-align: center;
      }
    }

    .uploadedImg {
      position: relative;
      width: calc(25% - 16px);
      margin: 0 8px;
      line-height: 0;

      img {
        width: 100%;
        height: 115px;
        border-radius: 8px;
        object-fit: cover;
      }

      .loader {
        position: absolute;
        background: #000000b8;
        border-radius: 10px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 40px;
          height: 40px;
          background-color: #fff;
          border-radius: 100px;
        }
      }

      .icon-cancel {
        position: absolute;
        right: 8px;
        top: 4px;
        font-size: 14px;
        color: $TXTPRIMARY_ONE;

        &.hid {
          display: none;
        }
      }
    }
  }

  .subscriptionBox {
    margin-bottom: 16px;

    span.MuiFormControlLabel-label {
      color: $TXTPRIMARY_ONE;
      padding-left: 6px;
    }

    .MuiFormControlLabel-root {
      margin-left: 0;
    }

    .chkBox {
      padding: 0;

      &:before {
        border-color: $BORDER_GREY;
        color: $TXTPRIMARY_ONE;
        font-size: $FONT16;
        top: 1px;
        left: 0;
        width: 14px;
        height: 14px;
      }

      svg {
        font-size: 18px;
        color: $TXTPRIMARY_ONE;
      }
    }
  }

  .customTextField {
    .position {
      &.valid {
        .withSign.inputControlStyle {
          .MuiOutlinedInput-root {
            &:before {
              content: "£";
              color: #4f4f4f;
            }
          }
        }
      }
    }
  }

  .btnWrap {
    .customLoadingBtnMain {
      button {
        border-color: transparent;
        margin: 0;
        color: $TXTWHITE;

        &.Mui-disabled {
          background-color: $BGGREY;
        }

        &:not(.Mui-disabled) {
          background-color: $BGPRIMARY_ONE;
        }
      }
    }

    button {
      width: 162px;
      border: 1px solid $TXTPRIMARY_ONE;
      border-radius: 10px;
      margin-left: 16px;
      height: 48px;
      font-weight: 800;
      font-size: $FONT14;
      color: $TXTPRIMARY_ONE;
      text-transform: initial;
      font-family: $OPENSANS;
    }
  }
}

.addPromoCode {
  .hasError {
    fieldset {
      border: 1px solid #FF2525 !important;
    }

    .masking {
      border: 1px solid #FF2525 !important;
    }

    .formGroupFieldTextArea {
      border: 1px solid #FF2525 !important;
    }

    textarea {
      border: 1px solid #FF2525 !important;
    }
  }
}

.addBrandModalWrap.MuiBox-root {
  max-width: 375px;
  width: 100%;
  background: $BGPRIMARY_TWO;
  border-radius: 8px;
  padding: 20px;
  border: none;

  .icon-close {
    position: absolute;
    right: 0;
    cursor: pointer;
    font-size: 24px;
    line-height: 1;
  }

  h5 {
    font-family: $OPENSANS;
    font-style: normal;
    font-weight: 600;
    font-size: $FONT16;
    line-height: 1.5;
    position: relative;
    margin-bottom: 24px;
    color: $TXTPRIMARY_ONE;
  }

  .btnWrap {
    .customLoadingBtnMain {
      button {
        border-color: transparent;
        margin: 0;
        color: $TXTWHITE;
        margin-bottom: 16px;

        &.Mui-disabled {
          background-color: $BGGREY;
        }

        &:not(.Mui-disabled) {
          background-color: $BGPRIMARY_ONE;
        }
      }
    }

    button {
      width: 100%;
      border: 1px solid $TXTPRIMARY_ONE;
      border-radius: 10px;
      height: 48px;
      font-weight: 800;
      font-size: $FONT14;
      color: $TXTPRIMARY_ONE;
      text-transform: initial;
      font-family: $OPENSANS;
    }
  }
}

@media (max-width: 1160px) {
  .manageProducts {
    .productList {
      .cardWrap.MuiPaper-root {
        width: calc(33.333% - 16px);
      }
    }
  }
}

@media (min-width: 1281px) and (max-width: 1440px) {

  .MuiBox-root .MuiContainer-root,
  .MuiBox-root+.MuiContainer-root {
    max-width: 1200px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .manageProducts {
    .productList {
      .cardWrap.MuiPaper-root {
        width: calc(50% - 16px);
      }
    }
  }
}

.hidecolor {
  .MuiMenu-list {
    li {
      display: none !important;
    }

  }
}