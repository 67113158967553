@import "../../../assets/scss/variables.scss";

.managerPromoCode {
  width: 100%;
  margin-top: 25px;
  background-color: $BODYBG;

  .managerPromoCodeContainer {
    max-width: 1295px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;

    .sectionTitle {
      padding: 24px 0;

      .titleText {
        font-size: 18px;
        line-height: 27px;
        font-weight: 700;
        color: $TXTPRIMARY_ONE;
      }
    }

    .managerPromoCodeSearchRow {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .searchBoxDiv {
        max-width: 461px;
        width: 100%;

        .searchInput {
          width: 100%;
        }
      }

      .MuiButton-outlined {
        border-radius: 8px;
        border: 1px solid $BORDER_SECONDARY;
        background-color: $BGWHITE;
        padding: 9px 16px;
        font-weight: 400;
        font-size: $FONT14;
        line-height: 21px;
        color: $TXTPRIMARY_ONE;
        text-transform: initial;

        .MuiButton-startIcon {
          color: $TXTSECONDARY;
          margin-right: 8px;
          line-height: 21px;
        }
      }
    }

    .managerPromoCodeTableCard {
      margin-top: 21px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      .promoCodetable {
        .tableHead {
          background-color: $BGPRIMARY_ONE;

          .tableheadTr {
            .MuiTableCell-root {
              color: $BGWHITE;
              background-color: $BGPRIMARY_ONE;
              font-size: $FONT14;
              line-height: 20px;
              font-weight: 600;
              font-family: $OPENSANS;

              &.productDetailsShowTitle {
                display: none;
              }
            }
          }
        }

        .tableBody {
          padding: 16px 20px 20px 23px;
          background-color: $BGWHITE;

          .tableBodyTr {
            .MuiTableCell-root {
              text-align: justify;
              color: $TXTPRIMARY_ONE;
              font-family: $OPENSANS;

              &:nth-child(5) {
                width: 120px;
              }

              &.firstCell {

                // display: none;
                .MuiBox-root {
                  .productDetailsShowTitle {
                    display: none;
                  }
                }
              }

              &.productDetailsShowTitle {
                display: none;
              }

              .MuiBox-root {
                .interDentTitle {
                  color: $TXTPRIMARY_ONE;
                  font-family: $OPENSANS;
                  font-weight: 600;
                  font-size: $FONT16;
                  line-height: 24px;
                }

                .interSubtitle {
                  font-weight: 400;
                  font-size: $FONT14;
                  line-height: 21px;
                  color: $TXTPRIMARY_ONE;
                  max-width: 300px;
                  width: 100%;
                }
              }

              .activeStatus {
                padding: 0 11px;
                height: 19px;
                width: auto;
                font-size: $FONT12;
                font-family: $OPENSANS;
                border-color: $BORDER_SECONDARY;

                span {
                  padding: 0;
                }
              }

              .expireStatus {
                color: $TXTRED;
              }

              .groupBtn {
                display: flex;
                align-items: center;
                min-width: inherit;
                gap: 9px;
                width: auto;

                button {
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 18px;
                  font-family: $OPENSANS;
                  min-width: auto;
                  padding: 0;
                  text-transform: initial;

                  &.editBtn {
                    color: $TXTPRIMARY_ONE;
                  }

                  &.deleteBtn {
                    color: $TXTRED;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.addPromoCodeModel {
  width: 100%;
  margin: 0px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;

  .addPromoCode {
    padding: 20px 30px 40px 30px;
    background: $BGPRIMARY_TWO;
    border-radius: 8px;
    max-width: 770px;
    width: 100%;

    * {
      font-family: $OPENSANS;
    }

    .addPromoCodeHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: 100%;
      position: relative;

      .title {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: $TXTPRIMARY_ONE;

        span {
          position: absolute;
          right: 0;
          font-size: 20px;
          top: 0;
          cursor: pointer;
        }
      }

      .closeIcon {
        font-size: 16px;
        color: $TXTPRIMARY_ONE;
      }
    }

    .addPromoCodeFormCard {
      display: flex;
      max-width: 100%;
      width: 100%;
      margin-top: 24px;

      .addPrmoCodeCardLeft {
        width: 100%;
        max-width: 50%;
        padding-right: 20px;

        .radioButtonGroup {
          .title {
            text-align: left;
            font-weight: 600;
            font-size: $FONT14;
            line-height: 21px;
            color: $TXTPRIMARY_ONE;
          }

          .radioBtnSingle {
            width: 100%;
            max-width: 100%;
            display: flex;
            align-items: center;
            margin-top: 16px;

            .RadioButton {
              &:first-child {
                margin-right: 15px;
              }
            }
            &.disabled {
              opacity: 0.6;
            }
          }
        }

        .formGroupField {
          .label {
            text-align: left;
            font-weight: 600;
            font-size: $FONT14;
            line-height: 21px;
            color: $TXTPRIMARY_ONE;
            margin-top: 16px;
          }

          .customTextField {
            margin-top: 16px;

            .position {
              .textfieldLabel {
                display: none;
              }

              .inputControlStyle {
                margin: 0px;

                .MuiOutlinedInput-root {
                  background: $BGWHITE;
                  outline: none;
                  border-radius: 10px;
                  border: none !important;
                  box-shadow: none;

                  .MuiInputBase-input {
                    color: $TXTPRIMARY_ONE !important;
                    font-weight: 400;
                    font-size: $FONT14;
                    line-height: 21px;

                    &::placeholder {
                      font-weight: 400;
                      font-size: $FONT14;
                      line-height: 21px;
                      color: $TXTOFFWHITE;
                      opacity: 2;

                      &::after {
                        content: "*";
                        color: $TXTRED;
                      }
                    }
                  }

                  .MuiOutlinedInput-notchedOutline {
                    border: 1px solid $BORDER_SECONDARY;
                  }
                }
              }

              .requiredField {
                display: block;
              }

              &.valid {
                .requiredField {
                  display: none;
                }
              }
            }

            .react-datepicker-wrapper {
              input {
                padding: 0 5px;
                width: 100%;
                border-radius: 10px;
                border: 1px solid #e8bcb1;
                font-weight: 400;
                font-size: 14px;
                padding: 14px 16px;
                outline: 0;
                height: 48px;
                color: $TXTPRIMARY_ONE;

                &::-webkit-input-placeholder {
                  /* Edge */
                  color: $TXTOFFWHITE;
                }

                &:-ms-input-placeholder {
                  /* Internet Explorer 10-11 */
                  color: $TXTOFFWHITE;
                }

                &::placeholder {
                  color: $TXTOFFWHITE;
                }
              }
            }

            .react-datepicker__day--disabled {
              cursor: not-allowed;
              opacity: 0.5;
            }

            .react-datepicker__navigation {
              top: 7px;
            }

            .errorMsg.show {
              top: 15px;
              position: relative;
            }
            &.disabled {
              opacity: 0.6;
            }
          }
          &.disabled {
            opacity: 0.6;
          }

          .hasValue {
            .position {
              .requiredField {
                display: none;
              }
            }
          }

          &.customDatePicker {
            position: relative;
            cursor: pointer;

            label.requiredField {
              position: absolute;
              color: $TXTOFFWHITE;
              font-size: $FONT14;
              top: 12px;
              left: 16px;
              pointer-events: none;

              span {
                color: $TXTRED;
              }
            }

            .icon {
              position: absolute;
              right: 16px;
              top: 12px;
              font-size: 18px;
              pointer-events: none;
              color: $TXTPRIMARY_ONE;
            }
          }
        }

        .formGroupFieldTextArea {
          margin-top: 16px;
          position: relative;
          .promoCodeDescreptionCount{
            position: absolute;
            bottom: 15px;
            right: 12px;
          }

          textarea {
            background: $BGWHITE;
            border: 1px solid $BORDER_SECONDARY;
            border-radius: 10px;
            padding: 12px;
            min-height: 90px;
            max-height: 90px;

            ~textarea {
              display: none;
            }
          }


          .iconWrap {
            display: none;
          }

          .formGroupFieldTextArea .requiredField.none-requred {
            top: 30px !important;
            left: 15px !important;
          }
          .requiredField{
            top: 15px !important;
            left: 15px !important;
          }


          .customTextField {
            .inputControlStyle{
              margin-bottom: 0px !important;
            }
            .position {
              .requiredField {
                display: block;
              }

              &.valid {
                .requiredField {
                  display: none;
                }
              }

              .requiredField {
                left: 8px;
                top: 3px;
              }

              .inputControlStyle {
                .MuiInputBase-root {
                  border: none;
                  border-radius: inherit;

                  textarea {
                    font-weight: 400;
                    font-size: $FONT14;
                    line-height: 21px;
                    color: $TXTPRIMARY_ONE;
                  }

                  &::placeholder {
                    font-weight: 400;
                    font-size: $FONT14;
                    line-height: 21px;
                    color: $TXTOFFWHITE;
                    opacity: 2;
                  }
                }
              }
            }

            .hasValue {
              .position {
                .requiredField {
                  display: none;
                }
              }
            }
          }

          .promoCodeDescreptionCount {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-weight: 400;
            font-size: 10px;
            line-height: 15px;
            color: $TXTOFFWHITE;

            &.red {
              color: $TXTRED;
            }
          }

          &.errorBorder {
            border: 1px solid $TXTRED;
          }
        }

        .formGroupFieldTextAreaError {
          margin-top: 8px;

          .charLimit {
            color: $TXTRED;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
          }
        }
      }

      .addPrmoCodeCardRight {
        width: 100%;
        max-width: 50%;
        padding-left: 20px;
        border-left: 1px solid $BORDER_GREY;

        .recurrenceSection {
          margin-bottom: 16px;

          .title {
            text-align: left;
            font-weight: 600;
            font-size: $FONT14;
            line-height: 21px;
            color: $TXTPRIMARY_ONE;
          }

          .radioBtn {
            display: flex;
            align-items: center;
            margin-top: 14px;

            .customRadioButton {
              width: 100%;
              padding: 8px 8px 8px 8px;
              max-width: 33.33%;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              margin-right: 16px;

              &:last-child {
                margin-right: 0px;
                min-width: 126px;
              }

              .MuiTypography-root {
                margin-bottom: 10px;
              }
            }
            &.disabled {
              opacity: 0.6;
            }
          }

          .howManyRepeat {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 14px;

            .MuiTypography-root {
              font-weight: 400;
              font-size: $FONT14;
              line-height: 21px;
              color: $TXTPRIMARY_ONE;
            }

            .MuiButton-outlinedPrimary {
              color: none;
              border: 1px solid none;
            }

            .MuiButton-outlined {
              color: none;
              border: 1px solid none;
            }

            .howManyIncrementDecrement {
              display: flex;
              width: 124px;
              height: 40px;
              margin-left: 10px;

              button {
                width: 36px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                font-size: 20px;

                &.decrementBtn {
                  background: $BGSECONDARY;
                  border-radius: 10px 0px 0px 10px;
                  cursor: pointer;
                  color: $TXTPRIMARY_ONE;
                  color: none;
                  border: 1px solid $BORDER_SECONDARY;
                  border-right: 0;
                }

                &.increment {
                  background: $BGPRIMARY_ONE;
                  border-radius: 0px 10px 10px 0px;
                  cursor: pointer;
                  color: $TXTWHITE;
                  border: 1px solid $BORDER_SECONDARY;
                  border-left: none;

                  span {
                    color: $TXTSECONDARY;
                  }
                }
              }

              .countText {
                width: 52px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: $BGWHITE;
                font-weight: 400;
                font-size: $FONT14;
                line-height: 21px;
                color: $TXTPRIMARY_ONE;
                border: 1px solid $BORDER_SECONDARY;
                border-left: none;
                border-right: none;
              }
            }
          }
        }

        .setCreteriaSection {
          // margin-top: 16px;
          margin-bottom: 16px;

          .title {
            font-weight: 600;
            font-size: $FONT14;
            line-height: 21px;
            color: $TXTPRIMARY_ONE;
          }

          .radioBtnGroup {
            margin-top: 16px;
            display: flex;
            align-items: center;

            .customRadioButton {
              width: 100%;
              max-width: 50%;

              &:nth-child(1) {
                margin-right: 15px;
              }
            }
            &.disabled {
              opacity: 0.6;
            }
          }
        }

        .formGroupField {
          .customTextField {
            .position {
              .inputControlStyle {
                .MuiInputBase-root {
                  border: none;
                  border-radius: inherit;

                  textarea {
                    font-weight: 400;
                    font-size: $FONT14;
                    line-height: 21px;
                    color: $TXTDARK;
                  }

                  &::placeholder {
                    font-weight: 400;
                    font-size: $FONT14;
                    line-height: 21px;
                    color: $TXTOFFWHITE;
                    opacity: 2;
                  }
                }
              }

              .requiredField {
                display: block;
              }

              .inputControlStyle {
                box-shadow: none;

                .MuiOutlinedInput-root {
                  background: $BGWHITE;
                  border: none !important;
                  outline: none;
                  border-radius: 10px;
                  box-shadow: none;

                  .MuiInputBase-input {
                    color: $TXTPRIMARY_ONE !important;
                    font-weight: 400;
                    font-size: $FONT14;
                    line-height: 20px;

                    &::placeholder {
                      font-weight: 400;
                      font-size: $FONT14;
                      line-height: 21px;
                      color: $TXTOFFWHITE;
                      opacity: 2;
                    }
                  }

                  .MuiOutlinedInput-notchedOutline {
                    border: 1px solid $BORDER_SECONDARY;
                  }
                }
              }

              &.valid {
                .requiredField {
                  display: none;
                }

                .MuiInputAdornment-root {
                  &:first-child {
                    &:before {
                      content: "£";
                      color: #3b4d61;
                    }
                  }
                }
              }
            }

            .hasValue {
              label.requiredField {
                display: none;
              }
            }
          }

          &.discount {
            .customTextField {
              .position.valid {
                .MuiInputAdornment-root {
                  &:first-child {
                    &:before {
                      display: none;
                    }
                  }
                }
              }
            }
          }

          &.formGroupone {
            // margin-top: 16px;
          }

          &.formGroupTwo {
            // margin-top: 16px;
          }

          &.formGroupThree {
            &.disabled {
              opacity: 0.5;
            }
            // margin-top: 16px;
          }

          &.discountField {
            &.notEmpty {
              .requiredField {
                display: none;
              }

              .MuiInputAdornment-root:first-child:before {
                content: "£";
                color: #3b4d61;
              }
            }
          }
        }
      }
    }

    .addPromoCodeGroupButton {
      display: flex;
      align-items: center;
      margin-top: 24px;

      .customLoadingBtnMain {
        &:nth-child(1) {
          .MuiButtonBase-root {
            margin-right: 10px;
            color: $TXTWHITE;
            background-color: $BGPRIMARY_ONE;
            box-shadow: none;

            &:disabled {
              background-color: $BGGREY;

              &:hover {
                background-color: $BGGREY;
              }
            }
          }
        }

        .outLineBtn {
          padding: 12px 10px;
          border: 1px solid #3b4d61;
          border-radius: 10px;
          font-weight: 800;
          font-size: $FONT14;
          line-height: 21px;
          color: $TXTPRIMARY_ONE;
          text-transform: capitalize;
          background-color: transparent;
        }

        .MuiButtonBase-root {
          margin: 0px;
          width: 162px;
        }
      }
    }
  }
}

.formGroupFieldTextAreaError:empty {
  display: none;
}

.requiredstar {
  position: relative;

  .requiredField {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #C0C6CC !important;
    opacity: 1;
    position: absolute;
    z-index: 1;
    top: 50%;
    margin-top: -19px;
    left: 16px;
    pointer-events: none;

    sup {
      color: #fd6464;
      padding-left: 3px;
    }
  }

  .none-requred {
    display: none !important;
  }

  &.disabled {
    opacity: 0.6;
  }
}

.amountLable{
  margin-top: -32px !important;
}

@media only screen and (min-width: 1281px) and (max-width: 1440px) {
  .managerPromoCode .managerPromoCodeContainer {
    max-width: 1200px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
  .managerPromoCode {
    .managerPromoCodeContainer {
      .managerPromoCodeTableCard {
        .promoCodetable {
          .tableBody {
            .tableBodyTr {
              .MuiTableCell-root.firstCell {
                .MuiBox-root {
                  .productDetailsShowTitle.show1279 {
                    display: block;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .managerPromoCode {
    .managerPromoCodeContainer {
      .managerPromoCodeTableCard {
        .promoCodetable {
          .hide1279 {
            display: none;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .managerPromoCode {
    .managerPromoCodeContainer {
      .managerPromoCodeTableCard {
        .promoCodetable {
          .tableHead {
            .tableheadTr {
              .MuiTableCell-root {
                &:nth-child(1) {
                  min-width: 300px;
                  width: 300px;
                }

                &:nth-child(7) {
                  min-width: 53px;
                  width: 53px;
                }

                &:nth-child(8) {
                  min-width: 82px;
                  width: 82px;
                }

                &.productDetailsShowTitle {
                  display: block;
                  border-bottom: none;
                  min-width: 204px;
                  width: 204px;
                }

                &.tableHideM {
                  display: none;
                }
              }
            }
          }

          .tableBody {
            .tableBodyTr {
              .MuiTableCell-root {
                padding: 8px 8px 8px 8px;

                &.firstCell {
                  .MuiBox-root {
                    .interDentTitle {
                      font-size: 16px;
                    }

                    .interSubtitle {
                      max-width: inherit;
                      font-size: $FONT14;
                    }

                    .productDetailsShowTitle {
                      display: block;

                      .productDetailsShowRow {
                        display: flex;
                        align-items: center;

                        .productKeyTitle {
                          margin-right: 8px;
                          font-weight: 400;
                          font-size: $FONT14;
                          line-height: 21px;
                          color: $TXTPRIMARY_ONE;
                        }

                        .productValueTitle {
                          font-weight: 400;
                          font-size: $FONT14;
                          line-height: 21px;
                          color: $TXTPRIMARY_ONE;
                        }
                      }
                    }
                  }
                }

                .groupBtn {
                  .editBtn {
                    font-size: 12px;
                  }

                  .deleteBtn {
                    font-size: 12px;
                  }
                }

                &.productDetailsShowTitle {
                  display: table-cell;

                  .productDetailsShowRow {
                    display: flex;
                    align-items: center;

                    .productKeyTitle {
                      margin-right: 8px;
                      font-weight: 400;
                      font-size: $FONT14;
                      line-height: 21px;
                      color: $TXTPRIMARY_ONE;
                      min-width: 128px;
                    }

                    .productValueTitle {
                      font-weight: 400;
                      font-size: $FONT14;
                      line-height: 21px;
                      color: $TXTPRIMARY_ONE;
                    }
                  }
                }

                &.tableHideM {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }

  .addPromoCodeModel {
    .addPromoCode {
      margin: 0 15px;
    }
  }
}