@import "../../assets/scss/variables.scss";

// .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root{
//     border: 1px solid red !important;
//                 box-shadow: none !important;
// }

fieldset.MuiOutlinedInput-notchedOutline {
  border: none;
}
.customTextField {
  width: 100%;
  display: flex;
  flex-direction: column;
  .loginpage {
    box-shadow: 0px 0px 22px rgba(0,0,0,0.08), 0px 0px 22px rgba(0,0,0,0.08);
    .MuiOutlinedInput-root.MuiInputBase-formControl {
      padding: 0 16px;
      &.Mui-focused {
        &.MuiOutlinedInput-notchedOutline {
          border: 1px solid $BORDER_ERROR;
        }
      }
    }
  }
  &.hasError.MuiBox-root {
    .loginpage {
      .MuiInputBase-root.MuiOutlinedInput-root {
        background: transparent;
      }
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid $BORDER_ERROR;
      }
    }
    .regularInput.inputControlStyle {
      .MuiOutlinedInput-notchedOutline {
        border-color: $BORDER_RED;
      }
      & + .errorMsg {
        color: $TXTRED;
      }
    }
  }
  .MuiInputBase-root {
    .iconWrap {
      .MuiIconButton-root {
        color: $TXTGRAY_MID;
        padding-right: 0;
        font-size: 16px;
        svg {
          font-size: $FONT15;
        }
      }
    }
  }
  .textfieldLabel {
    font-size: $FONT14;
    line-height: 1.4375em;
    font-family: $MONTSERRAT;
    font-weight: 400;
    color: $TXTBLACK;
  }
  .inputControlStyle {
    margin-bottom: 16px;
    &.txtarea {
      .MuiOutlinedInput-root {
        padding: 0 !important;
        .MuiInputBase-input {
          margin: 14px 10px 16px;
          padding: 0;
          overflow-y: auto !important;
        }
      }
      textarea {
        height: 44px !important;
        line-height: 1;
      }
    }
    .MuiOutlinedInput-root {
      font-family: $OPENSANS;
      font-size: $FONT14;
      border-radius: 10px;
      color: $TXTWHITE;
      background-color: rgba(241, 241, 241, 0.2);
      -webkit-box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08),
        0px 0px 32px rgba(0, 0, 0, 0.02);
      -moz-box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08),
        0px 0px 32px rgba(0, 0, 0, 0.02);
      -ms-box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08),
        0px 0px 32px rgba(0, 0, 0, 0.02);
      -o-box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08),
        0px 0px 32px rgba(0, 0, 0, 0.02);
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08),
        0px 0px 32px rgba(0, 0, 0, 0.02);
      ::-webkit-input-placeholder {
        /* Edge */
        color: #fff;
      }
      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #fff;
      }
      ::placeholder {
        color: #fff;
      }
      .MuiInputAdornment-root {
        margin-right: 0;
        i {
          font-size: $FONT22;
          color: $TXTGRAY_MID;
        }
      }
      .MuiOutlinedInput-input {
        padding: 14px 0;
        font-size: $FONT16;
        height: 48px;
        box-sizing: border-box;
        color: $TXTPRIMARY_TWO;
        font-family: $OPENSANS;
      }
    }
  }
  .regularInput {
    .MuiOutlinedInput-root {
      padding-left: 16px;
      box-shadow: none !important;
      border: none;
      background-color: $BGWHITE;
      .MuiInputBase-input {
        color: $TXTGREYTEXTBOX !important;
        font-weight: 400;
        font-size: $FONT14;
        &::-webkit-input-placeholder {
          /* Edge */
          color: $TXTOFFWHITE;
          opacity: 1;
          font-size: $FONT14 !important;
        }
        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: $TXTOFFWHITE;
          opacity: 1;
        }
        &::placeholder {
          color: $TXTOFFWHITE;
          opacity: 1;
        }
      }
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid $BORDER_SECONDARY;
      }
      &.Mui-focused {
        .MuiOutlinedInput-notchedOutline {
          border: 1px solid $BORDER_SECONDARY;
        }
      }
    }
  }
  .errorMsg {
    &.show {
      margin-bottom: 18px;
      margin-top: -8px;
      font-family: $OPENSANS;
      color: $TXTRED;
      font-size: $FONT12;
      line-height: 18px;
      text-align: left;
    }

    .green {
      // color: $SECUSESSCOLOR;
    }
  }
  .passwordcustom {
    .red {
      color: $TXTYELLOW;
      .MuiBox-root {
        .icon {
          padding-right: 6px;
        }
      }
    }
    .green {
      color: #00c700;
      .MuiBox-root {
        .icon {
          padding-right: 6px;
        }
      }
    }
  }
  .position {
    position: relative;
    .requiredField {
      position: absolute;
      left: 16px;
      top: 13px;
      margin: 0px;
      color: #c0c6cc;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      display: none;
      pointer-events: none;
      &::after {
        content: "*";
        color: #fd5d5d;
      }
    }
    &.valid {
      .requiredField {
        display: none;
      }
    }
    
  }
}
.formInput {
  color: $TXTWHITE;
  .chkBox {
    &:before {
      content: "";
      width: 16px;
      height: 16px;
      border: 1px solid $BORDER_WHITE;
      position: absolute;
      left: 12px;
      top: 12px;
      display: block;
      border-radius: 3px;
    }
    &.Mui-checked {
      &:before {
        border: none;
        content: '\e947';
        font-family: 'icomoon';
        color: $TXTWHITE;
        font-size: 18px;
      }
    }
    svg {
      color: $TXTPRIMARY_TWO;
      visibility: hidden;
    }
  }
}
.masking {
  margin-bottom: 16px;
  border: 1px solid $BORDER_SECONDARY;
  border-radius: 10px;
  overflow: hidden;
  background-color: $BGWHITE;
  .MuiOutlinedInput-root{
    padding: 13px 16px;
      input.MuiInputBase-input {
          line-height: 20px;
          outline: 0px;
          width: 100%;
          border: none;
          font-family: $OPENSANS;
          font-size: $FONT14;
          color: $TXTPRIMARY_ONE;
          &::placeholder {
              font-weight: 400;
              font-size:14px;
              line-height: 22px;
              display: flex;
              align-items: center;
              color: $TXTOFFWHITE !important;
              opacity: 1;
          }
      }

      .MuiInputBase-inputAdornedStart {
          display: none !important;
      }
    }
}