@import "../../assets/scss/variables.scss";

.RadioButton {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    width: 100%;
    input[type="radio"] {
        position: absolute;
        opacity: 0;
        left: 3px;
        top: 3px;

        +label {
            margin: 0;
            font-size: 14px;
            font-weight: normal;
            display: flex;
            align-items: center;
            width: 55px;
            height: 36px;
            border-radius: 10px;
            cursor: pointer;
            color: #221747;
            font-weight: 300;
            font-size: $FONT14;
            justify-content: center;

            &:before {
                content: "";
                display: block;
                border: 1px solid #E8BCB1;
                border-radius: 10px;
                width: 100%;
                height: 34px;
                position: absolute;
                left: 0;
                cursor: pointer;
                text-align: center;
                transition: all 250ms ease;
            }
        }

        &:checked {
            +label {
                background: $TXTPRIMARY_ONE;
                border: none;
                width: 100%;
                color: $TXTWHITE;

                &:before {
                    border: none;
                }

                &:after {
                    opacity: 1;
                    z-index: 1;
                }
            }
        }

        &:focus {
            +label {
                &:before {
                    outline: none;
                }
            }
        }

        &:disabled {
            +label {
                cursor: not-allowed;

                &:before {
                    opacity: 0.5;
                }

                &:after {
                    opacity: 0.5;
                }

                // &::after {
                //  box-shadow: inset 0 0 0 4px $bgDarkPink;
                //  border-color: darken($bgDarkPink, 25%);
                //  background: darken($bgDarkPink, 25%);
                // }
            }
        }
    }
}