@import "../../../../assets/scss/variables.scss";

.ResetPasswordMain {
  width: 100%;
  float: left;
  overflow: hidden;
  &.disable {
    pointer-events: none;
  }

  .signinLeft {
    height: 100%;
    background-color: $BGSECONDARY;
    display: flex;
    align-items: center;
    justify-content: center;

    .centerContent {
      margin: 0 auto;
      .logo {
        max-width: 100%;
        height: auto;
        display: block;
        margin-bottom: 26px;
      }
      h1 {
        font-family: $MONTSERRAT;
        color: $TXTWHITE;
        font-size: $FONT48;
        line-height: 59px;
        span {
          font-family: $MONTSERRAT;
          font-size: $FONT48;
          padding-left: 8px;
        }
      }
    }
  }

  .signinRight {
    width: 50%;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: $BGPRIMARY_ONE;
    form {
      width: 335px;
      .submitBtn {
        .MuiLoadingButton-root {
          margin-bottom: 4px;
        }
      }
      .MuiLink-root {
        display: flex;
        justify-content: center;
        text-decoration: none;
        font-family: $OPENSANS;
        font-size: $FONT16;
        color: #f1f1f1;
        font-weight: 600;
      }
      .errorMsg {
        &.show {
          color: $TXTYELLOW;
        }
      }
    }
    .rememberField {
      margin-bottom: 18px;
    }

    .upperContent {
      width: 335px;
      margin-top: 55px;
      .title {
        color: $TXTWHITE;
        font-size: $FONT20;
        font-family: $MONTSERRAT;
        font-weight: 800;
        margin-bottom: 19px;
      }
      .subTitle {
        color: $TXTBLACK;
        font-size: $FONT16;
        font-family: $MONTSERRAT;
        margin-bottom: 32px;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .ResetPasswordMain {
    & > div {
      flex-grow: 1;
    }
    display: flex;
    flex-direction: column;
    .signinLeft {
      width: 100%;
      .centerContent {
        .logo {
          max-width: 194px;
          height: auto;
        }
        h1 {
          font-size: $FONT30;
          line-height: 38px;
          span {
            font-size: $FONT30;
            line-height: 38px;
          }
        }
      }
    }
    .signinRight {
      width: 100%;
      max-width: 100%;
      .upperContent {
        max-width: 302px;
      }
      form {
        max-width: 302px;
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .ResetPasswordMain {
    flex-direction: column;
    .signinLeft {
      width: 100%;
      max-width: 100%;
      flex-basis: 30%;
      height: 30vh;
      .centerContent {
        .logo {
          max-width: 194px;
          height: 100%;
          margin-bottom: 0;
        }
        h1 {
          font-size: $FONT30;
          line-height: 38px;
          span {
            font-size: $FONT30;
            line-height: 38px;
          }
        }
      }
    }
    .signinRight {
      width: 100%;
      max-width: 100%;
      flex-basis: 70%;
      height: 70vh !important;
      .upperContent {
        max-width: 302px;
      }
      form {
        max-width: 302px;
      }
    }
  }
}
@media screen and (max-width: 1024px) and (orientation: landscape) {
  .ResetPasswordMain {
    display: block;
    .signinLeft {
      .centerContent {
        .logo {
          max-width: 194px;
          height: auto;
        }
        h1 {
          font-size: $FONT30;
          line-height: 38px;
          span {
            font-size: $FONT30;
            line-height: 38px;
          }
        }
      }
    }
    .signinRight {
      .upperContent {
        max-width: 302px;
      }
      form {
        max-width: 302px;
        .submitBtn {
          .customBtn.MuiButton-root {
            font-size: $FONT14;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1025px) and (max-width: 1279px) {
  .ResetPasswordMain {
    .signinLeft {
      .centerContent {
        .logo {
          max-width: 194px;
          height: 100%;
          width: 100%;
        }
        h1 {
          font-size: $FONT30;
          line-height: 38px;
          span {
            font-size: $FONT30;
            line-height: 38px;
          }
        }
      }
    }
    .signinRight {
      width: 50%;
      .upperContent {
        max-width: 302px;
      }
      form {
        max-width: 302px;
        .submitBtn {
          .customBtn.MuiButton-root {
            font-size: $FONT14;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1280px) and (max-width: 1439px) {
  .ResetPasswordMain {
    .signinLeft {
      width: 50%;
    }
    .signinRight {
      width: 50%;
      .upperContent {
        max-width: 335px;
      }
      form {
        max-width: 335px;
        .submitBtn {
          .customBtn.MuiButton-root {
            font-size: $FONT14;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1440px) and (max-width: 1919px) {
  .ResetPasswordMain {
    .signinRight {
      .upperContent {
        max-width: 335px;
      }
      form {
        max-width: 335px;
        .submitBtn {
          .customBtn.MuiButton-root {
            font-size: $FONT14;
          }
        }
      }
    }
  }
}
