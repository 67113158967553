@import "../../assets/scss/variables.scss";

.oneTimeSubcribe {
  margin-top: 24px;
  width: 100%;
  background-color: $BGLIGHTGREY;
  height: 100%;

  .mainContainer {
    width: 100%;
    max-width: 1295px;
    margin: 0 auto;
    padding: 0 15px;

    .backToButton {
      padding-top: 23px;

      .MuiButtonBase-root {
        padding: 0px;
        min-width: auto;
        text-transform: capitalize;

        .MuiButton-startIcon {
          color: $TXTPRIMARY_ONE;
          margin-left: 0px;
          margin-right: 7px;
        }

        font-weight: 400;
        font-size: 14px;
        color: $TXTPRIMARY_ONE;
        font-family: $OPENSANS;
      }
    }

    .topSelectedUserData {
      margin-top: 20px;
      background: $BGPRIMARY_TWO;
      border-radius: 8px;
      padding: 8px 14px;
      display: flex;
      justify-content: space-between;

      .leftSecletedData {
        display: flex;

        .userShortName {
          background: $BGPRIMARY_ONE;
          box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
            0px 0.5px 2px rgba(96, 97, 112, 0.16);
          border-radius: 50%;
          width: 48px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 600;
          font-size: 16px;
          font-family: $OPENSANS;
          color: $BGWHITE;
        }

        .userFullDetails {
          margin-left: 16px;

          .userActiveStatus {
            display: flex;
            align-items: center;
            padding-top: 5px;

            .MuiTypography-root {
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: $TXTPRIMARY_ONE;
              font-family: $OPENSANS;
              margin-right: 8px;
            }

            .activeStatus {
              padding: 0px 11px;
              border: 1px solid $BORDER_SECONDARY;
              border-radius: 10px;
              font-weight: 400;
              font-size: 12px;
              color: $TXTPRIMARY_ONE;
              font-family: $OPENSANS;
              height: 19px;

              .MuiChip-label {
                padding: 0px;
              }
            }
          }

          .userIdInformation {
            display: flex;
            margin-top: 15px;

            .userIdInformationColmun {
              margin-right: 64px;

              &:last-child {
                margin-right: 0px;
              }

              .titleKey {
                font-weight: 400;
                font-size: 10px;
                line-height: 15px;
                color: $TXTPRIMARY_ONE;
                font-family: $OPENSANS;
              }

              .titleValue {
                font-weight: 400;
                font-size: 10px;
                line-height: 15px;
                color: $TXTPRIMARY_ONE;
                font-family: $OPENSANS;
                margin-top: 4px;
              }
            }
          }
        }
      }

      .rightSelectedDataStatusActive {
        .deleteBtn {
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: $TXTRED;
          font-family: $OPENSANS;
          text-transform: capitalize;
          padding: 0px;
          min-width: inherit;
          margin-right: 16px;
        }
      }
    }

    .usermanagementTabbing {
      margin-top: 20px;

      .tabWrap {
        .MuiTabs-root {
          .MuiTabs-scroller {
            .MuiButtonBase-root {
              color: #828282;
              font-weight: 400;
              font-size: $FONT14;
              line-height: 21px;

              &.css-cjctlb-MuiButtonBase-root-MuiTab-root {
                max-width: auto;
                min-width: auto;
                min-height: auto;
              }

              &.Mui-selected {
                color: $TXTPRIMARY_TWO;
              }
            }
          }
        }
      }

      .mobilesizeShow {
        display: none;
      }

      .MuiTabPanel-root {
        background: $BGPRIMARY_TWO;

        .searchBoxDiv {
          width: 461px;

          .searchInput {
            width: 100%;
          }
        }

        .userManagementOntimeDataListing {
          margin-top: 16px;
          border-radius: 10px 10px 10px 10px;
          overflow: hidden;

          .UserManagementtable {
            .tableHeader {
              background-color: $BGPRIMARY_ONE;

              .tableHeaderRow {
                .tableHeaderColumn {
                  font-weight: 600;
                  font-size: $FONT14;
                  line-height: 20px;
                  color: $TXTPRIMARY_TWO;
                  font-family: $OPENSANS;
                  padding: 16px 20px 15px 0px;
                  background-color: $BGPRIMARY_ONE;

                  &:nth-child(1) {
                    padding-left: 81px;
                    width: 300px;
                  }
                }
              }
            }

            .tableBody {
              &:last-child {
                border-bottom: none;
              }

              .tableBodyRow {
                background: $TXTWHITE;
                box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
                  0px 0.5px 2px rgba(96, 97, 112, 0.16);

                .tableBodyColumn {
                  font-weight: 400;
                  font-size: $FONT14;
                  line-height: 21px;
                  color: $TXTPRIMARY_ONE;
                  font-family: $OPENSANS;
                  padding: 7px 20px 7px 15px;

                  &:nth-child(2) {
                    width: 51%;
                  }

                  &:not(:first-child) {
                    padding-left: 0px;
                  }

                  &:last-child {
                    padding-right: 25px;
                  }

                  .listingImageBoxRow {
                    display: flex;
                    align-items: center;

                    .listingImageBoxRow {
                      margin-right: 19px;

                      .image {
                        width: 47px;
                        height: 48px;
                        border-radius: 10px;
                      }
                    }

                    .brushColor {
                      width: 16px;
                      height: 16px;
                      border-radius: 2px;
                      margin-right: 5px;
                    }

                    .productTitleName {
                      font-weight: 600;
                      font-size: $FONT14;
                      line-height: 21px;
                      color: $BGPRIMARY_ONE;
                      font-family: $OPENSANS;
                    }

                    .productSize {
                      font-weight: 400;
                      font-size: $FONT12;
                      font-family: $OPENSANS;
                      color: $BGPRIMARY_ONE;
                      padding-left: 5px;
                      line-height: 18px;
                    }
                  }

                  .downloadOnetimeData {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .productTitle {
                      font-weight: 400;
                      font-size: $FONT14;
                      line-height: 21px;
                      color: $TXTPRIMARY_ONE;
                      font-family: $OPENSANS;
                    }

                    .downloadFile {
                      .icon-download {
                        color: $TXTPRIMARY_ONE;
                      }
                    }

                    .MuiButtonBase-root-MuiIconButton-root {
                      padding: 0px;
                    }
                  }
                }

                &:last-child {
                  .tableBodyColumn {
                    border: none;
                  }

                  box-shadow: none;
                }
              }
            }
          }
        }

        .pastSubscribesTitle {
          font-weight: 400;
          font-size: $FONT16;
          line-height: 24px;
          color: $TXTPRIMARY_ONE;
          font-family: $OPENSANS;
          margin: 16px 0 0;
        }

        .pastSubscribesSection {
          .pastSubscribesTitle {
            font-weight: 400;
            font-size: $FONT16;
            line-height: 24px;
            color: $TXTPRIMARY_ONE;
            font-family: $OPENSANS;
            margin: 16px 0 0;
          }

          .userManagementPastSubscribeListing {
            border-radius: 10px 10px 10px 10px;
            overflow: hidden;

            .UserManagementtable {
              .tableHeader {
                background-color: $BGPRIMARY_ONE;

                .tableHeaderRow {
                  .tableHeaderColumn {
                    font-weight: 600;
                    font-size: $FONT14;
                    line-height: 20px;
                    color: $TXTPRIMARY_TWO;
                    font-family: $OPENSANS;
                    background-color: $BGPRIMARY_ONE;

                    &:nth-child(1) {
                      padding-left: 81px;
                      width: 300px;
                    }
                  }
                }
              }

              .tableBody {
                .tableBodyRow {
                  background-color: $BGWHITE;

                  .tableBodyColumn {
                    font-weight: 400;
                    font-size: $FONT14;
                    line-height: 21px;
                    color: $TXTPRIMARY_ONE;
                    font-family: $OPENSANS;
                    padding: 7px 20px 7px 15px;

                    .listingImageBoxRow {
                      display: flex;
                      align-items: center;

                      .listingImageBoxRow {
                        margin-right: 19px;

                        .image {
                          width: 47px;
                          height: 48px;
                          border-radius: 10px;
                        }
                      }

                      .brushColor {
                        width: 16px;
                        height: 16px;
                        border-radius: 2px;
                        margin-right: 5px;
                      }

                      .productTitleName {
                        font-weight: 600;
                        font-size: $FONT14;
                        line-height: 21px;
                        color: $BGPRIMARY_ONE;
                        font-family: $OPENSANS;
                      }

                      .productSize {
                        font-weight: 400;
                        font-size: $FONT12;
                        font-family: $OPENSANS;
                        color: $BGPRIMARY_ONE;
                        padding-left: 5px;
                        line-height: 18px;
                      }
                    }

                    .downloadOnetimeData {
                      display: flex;
                      align-items: center;
                      justify-content: space-between;

                      .productTitle {
                        font-weight: 400;
                        font-size: $FONT14;
                        line-height: 21px;
                        color: $TXTPRIMARY_ONE;
                        font-family: $OPENSANS;
                      }

                      .MuiButtonBase-root-MuiIconButton-root {
                        padding: 0px;

                        .icon-download {
                          color: $TXTPRIMARY_ONE;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1281px) and (max-width: 1440px) {
  .oneTimeSubcribe {
    .mainContainer {
      max-width: 1225px;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .oneTimeSubcribe {
    .mainContainer {
      padding: 0px 20px;

      .usermanagementTabbing {
        .desktopsizeTab {
          display: none;
        }

        .mobilesizeShow {
          display: block;
        }

        .MuiTabPanel-root {
          .userManagementOntimeDataListing {
            .UserManagementtable {
              .tableBody {
                .tableBodyRow {
                  .tableBodyColumn {
                    &:nth-child(2) {
                      width: 20%;
                    }

                    &:first-child {
                      width: 55%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

#test {
  .tableBodyRow {
    background: $TXTWHITE;
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
      0px 0.5px 2px rgba(96, 97, 112, 0.16);

    .tableBodyColumn {
      font-weight: 400;
      font-size: $FONT14;
      line-height: 21px;
      color: $TXTPRIMARY_ONE;
      font-family: $OPENSANS;
      padding: 7px 20px 7px 15px;

      &:nth-child(2) {
        width: 51%;
      }

      &:not(:first-child) {
        padding-left: 0px;
      }

      &:last-child {
        padding-right: 25px;
      }

      .listingImageBoxRow {
        display: flex;
        align-items: center;

        .listingImageBoxRow {
          margin-right: 19px;

          .image {
            width: 47px;
            height: 48px;
            border-radius: 10px;
          }
        }

        .brushColor {
          width: 16px;
          height: 16px;
          border-radius: 2px;
          margin-right: 5px;
        }

        .productTitleName {
          font-weight: 600;
          font-size: $FONT14;
          line-height: 21px;
          color: $BGPRIMARY_ONE;
          font-family: $OPENSANS;
        }

        .productSize {
          font-weight: 400;
          font-size: $FONT12;
          font-family: $OPENSANS;
          color: $BGPRIMARY_ONE;
          padding-left: 5px;
          line-height: 18px;
        }
      }

      .downloadOnetimeData {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .productTitle {
          font-weight: 400;
          font-size: $FONT14;
          line-height: 21px;
          color: $TXTPRIMARY_ONE;
          font-family: $OPENSANS;
        }

        .downloadFile {
          .icon-download {
            color: $TXTPRIMARY_ONE;
          }
        }

        .MuiButtonBase-root-MuiIconButton-root {
          padding: 0px;
        }
      }
    }

    &:last-child {
      .tableBodyColumn {
        border: none;
      }

      box-shadow: none;
    }
  }
}