@import "./assets/scss/variables.scss";

body {
  margin: 0;
  padding: 0;
  font-family: $OPENSANS;
  background-color: $BODYBG ;
  color: $TXTPRIMARY_ONE;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
  overflow: hidden;
  .MuiTypography-root,.MuiTableCell-root{
    font-family: $OPENSANS;
    color: $TXTPRIMARY_ONE;
  }
  .MuiTableCell-root{
    font-size: 14px;
    .MuiTypography-root{
      font-size: 14px;
    }
  }
  table{
    tr{
      td{
        word-break: break-word;
      }
    }
  }
  button{
    word-break: normal;
  }
}
a:link,
a:visited {
  text-decoration: none;
  transition: 0.2s;
}
.MuiTouchRipple-childLeaving, .MuiTouchRipple-ripplePulsate{
  display: none;
}
.MuiBox-root.no-data,.no-data {
  text-align: center;
  width: 100%;
  margin: 20px 0;
  color: $TXTPRIMARY_ONE;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-ExtraBold.woff2") format("woff2"),
    url("./assets/fonts/Montserrat-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Medium.woff2") format("woff2"),
    url("./assets/fonts/Montserrat-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Regular.woff2") format("woff2"),
    url("./assets/fonts/Montserrat-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Bold.woff2") format("woff2"),
    url("./assets/fonts/Montserrat-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Light.woff2") format("woff2"),
    url("./assets/fonts/Montserrat-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Black.woff2") format("woff2"),
    url("./assets/fonts/Montserrat-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-ExtraLight.woff2") format("woff2"),
    url("./assets/fonts/Montserrat-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Thin.woff2") format("woff2"),
    url("./assets/fonts/Montserrat-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-SemiBold.woff2") format("woff2"),
    url("./assets/fonts/Montserrat-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Opensans";
  src: url("./assets/fonts/OpenSans-Bold.woff2") format("woff2"),
    url("./assets/fonts/OpenSans-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Opensans";
  src: url("./assets/fonts/OpenSans-ExtraBold.woff2") format("woff2"),
    url("./assets/fonts/OpenSans-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Opensans";
  src: url("./assets/fonts/OpenSans-Medium.woff2") format("woff2"),
    url("./assets/fonts/OpenSans-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Opensans";
  src: url("./assets/fonts/OpenSans-SemiBold.woff2") format("woff2"),
    url("./assets/fonts/OpenSans-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Opensans";
  src: url("./assets/fonts/OpenSans-Regular.woff2") format("woff2"),
    url("./assets/fonts/OpenSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "icomoon";
  src: url("./assets/fonts/icomoon.eot?ewxrtd");
  src: url("./assets/fonts/icomoon.eot?ewxrtd#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/icomoon.ttf?ewxrtd") format("truetype"),
    url("./assets/fonts/icomoon.woff?ewxrtd") format("woff"),
    url("./assets/fonts/icomoon.svg?ewxrtd#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
* p{
  font-family: $OPENSANS !important;
}
.MuiBox-root .MuiContainer-root,.MuiBox-root + .MuiContainer-root{
  max-width: 1285px;
}
.MuiBox-root{
  outline: none;
}

h1.mainTitle {
  font-family: $OPENSANS;
  font-style: normal;
  font-weight: 700;
  font-size: $FONT18;
  line-height: 150%;
  padding: 24px 0;
  color: $TXTPRIMARY_ONE;
}
.tabWrap {
  border-bottom: none !important;
  .MuiTabs-root{
    min-height: 40px;
  button {
    font-size: $FONT14;
    font-family: $OPENSANS;
    font-weight: 400;
    text-transform: initial;
    line-height: 1.5;
    border: 1px solid $BORDER_SECONDARY;
    background-color: $BGWHITE;
    border-radius: 10px 10px 0px 0px;
    margin-right: 8px;
    border-bottom: 0;
    color: $TXTPRIMARY_ONE;
    opacity: 1;
    padding: 9px 11px;
    min-height: 40px;
    min-width: auto;
    &.Mui-selected {
      background: $BGPRIMARY_ONE;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
      color: $TXTWHITE;
      border: none;
      font-weight: 600;
    }
  }
}
}
.PrivateTabIndicator-colorSecondary-3 {
  background-color: transparent !important;
}
.MuiTabPanel-root {
  background: #F1F1F1;
  padding: 14px !important;
  overflow-y: auto;
}

.react-datepicker{
  background-color: $BGPRIMARY_ONE;
  border: none;
}
.react-datepicker__header {
  background-color: transparent;
  padding-top: 12px;
  border: none;
  margin-top: 6px;
}
.react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 32px;
    line-height: 32px;
    font-family: $OPENSANS;
    font-size: $FONT14;
    margin: 7px;
  }
  .react-datepicker__day {
    color: $TXTWHITE;
  }
  .react-datepicker__day-name,
  .react-datepicker__current-month {
    color: $TXTSECONDARY;
  }
  .react-datepicker__current-month {
    font-size: $FONT16;
    font-weight: normal;
    
  }
  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    background-color: $BGSECONDARY;
    color: $TXTPRIMARY_ONE;
  }
  .react-datepicker__day-names {
    margin-bottom: -17px;
    margin-top: 23px;
  }
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker__navigation-icon::before {
    border-width: 2px 2px 0 0;
    top: 17px;
    width: 7px;
    height: 7px;
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected,
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background-color: $BGSECONDARY !important;
    color: $TXTPRIMARY_ONE !important;
  }
  .custom-arrows{
  .react-datepicker__navigation--previous {
    left: 87px;
  }
  .react-datepicker__navigation--next {
    right: 87px;
  }

}
.react-datepicker__navigation {
  top: 4px;
}
  .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after{
    border-bottom-color: $BORDER_PRIM1;
  }
  .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
    border-bottom-color: $BORDER_PRIM1;
}
.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before,.react-datepicker__navigation:hover *{
  border-color: $BORDER_SECONDARY !important;
}



[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.react-datepicker{
  font-family: $OPENSANS;
}
.icon-bell:before {
  content: "\e900";
}
.icon-eye:before {
  content: "\e901";
}
.icon-eyeoff:before {
  content: "\e902";
}
.icon-pin-code:before {
  content: "\e903";
}
.icon-pound:before {
  content: "\e904";
}
.icon-box:before {
  content: "\e905";
}
.icon-bell2:before {
  content: "\e906";
}
.icon-user:before {
  content: "\e907";
}
.icon-copy:before {
  content: "\e908";
}
.icon-minus-square:before {
  content: "\e909";
}
.icon-plus-square:before {
  content: "\e90a";
}
.icon-user2:before {
  content: "\e90b";
}
.icon-key-skeleton-alt:before {
  content: "\e90c";
}
.icon-power:before {
  content: "\e90d";
}
.icon-mail:before {
  content: "\e90e";
}
.icon-calender:before {
  content: "\e90f";
}
.icon-file-copy-alt:before {
  content: "\e910";
}
.icon-search:before {
  content: "\e911";
}
.icon-chevron-down:before {
  content: "\e912";
}
.icon-chevron-up:before {
  content: "\e913";
}
.icon-angle-left-b:before {
  content: "\e914";
}
.icon-angle-right-b:before {
  content: "\e915";
}
.icon-clock:before {
  content: "\e916";
}
.icon-lock:before {
  content: "\e917";
}
.icon-life-buoy:before {
  content: "\e918";
}
.icon-file-text:before {
  content: "\e919";
}
.icon-map-pin:before {
  content: "\e91b";
}
.icon-dollar-sign:before {
  content: "\e91c";
}
.icon-pin:before {
  content: "\e91d";
}
.icon-download:before {
  content: "\e91e";
}
.icon-filter:before {
  content: "\e91f";
}
.icon-trash-alt:before {
  content: "\e920";
}
.icon-play:before {
  content: "\e921";
}
.icon-subscribe:before {
  content: "\e922";
}
.icon-upload:before {
  content: "\e923";
}
.icon-arrow-right:before {
  content: "\e924";
}
.icon-arrow-left:before {
  content: "\e925";
}
.icon-check-circle:before {
  content: "\e926";
}
.icon-circle:before {
  content: "\e927";
}
.icon-help-icon:before {
  content: "\e928";
}
.icon-book-open:before {
  content: "\e929";
}
.icon-trolley:before {
  content: "\e92a";
}
.icon-notification:before {
  content: "\e92b";
}
.icon-my-profile:before {
  content: "\e92c";
}
.icon-shopping-bag:before {
  content: "\e92d";
}
.icon-house:before {
  content: "\e92e";
}
.icon-x-circle:before {
  content: "\e92f";
}
.icon-Frame:before {
  content: "\e930";
}
.icon-info-circle:before {
  content: "\e931";
}
.icon-square:before {
  content: "\e932";
}
.icon-check-square:before {
  content: "\e933";
}
.icon-search2:before {
  content: "\e91a";
}
.icon-plus-11:before {
  content: "\e934";
}
.icon-warning:before {
  content: "\e935";
}
.icon-close:before {
  content: "\e936";
}
.icon-thumbnail:before {
  content: "\e937";
}
.icon-cancel:before {
  content: "\e938";
}
.icon-onetime:before {
  content: "\e939";
}
.icon-drag:before {
  content: "\e93a";
}
.icon-play-filled .path1:before {
  content: "\e93b";
  color: rgb(59, 77, 97);
}
.icon-play-filled .path2:before {
  content: "\e93c";
  margin-left: -1em;
  color: rgb(232, 188, 177);
}
.icon-checked .path1:before {
  content: "\e93d";
  color: rgb(241, 241, 241);
}
.icon-checked .path2:before {
  content: "\e93e";
  margin-left: -1.0498046875em;
  color: rgb(59, 77, 97);
}
input.MuiOutlinedInput-input:-webkit-autofill {
  color: #3B4D61 !important;
}
.icon-plus-square2:before {
  content: "\e93f";
}
.icon-icon-check-filled:before {
  content: "\e940";
}
.icon-minus-1:before {
  content: "\e941";
}
.icon-plus-1:before {
  content: "\e942";
}
.icon-key1:before {
  content: "\e943";
}
.icon-sort-icon:before {
  content: "\e944";
}
.icon-check-empty:before {
  content: "\e945";
  color: #c0c6cc;
}
.icon-time-status:before {
  content: "\e946";
}
.icon-check-white:before {
  content: "\e947";
  color: #fff;
}

.MuiSnackbar-root {
  .MuiAlert-root {
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 12px 26px;
    text-align: center;
    justify-content: space-around;
    color: $TXTGREEN;
    font-weight: 500;
    font-size: $FONT14;
    
    width: 343px;
    .MuiAlert-action {
      display: none;
    }
    .MuiAlert-message{
      text-transform: initial;
    }
    
  }
  .MuiAlert-standardError{
    color: $TXTORANGE;
  }
}
.MuiAlert-root {
  .MuiAlert-icon {
    display: none;
  }
}



.MuiTooltip-popper .MuiTooltip-tooltipArrow  {
  background-color: #000 !important;
}
.MuiTooltip-arrow::before {
  color: #000;
}
  .notificationBtnDiv {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
  }
@media only screen and (min-width: 1281px) and (max-width: 1440px){
  .MuiBox-root .MuiContainer-root, .MuiBox-root + .MuiContainer-root{
    max-width: 1200px;
  }
}
@media (min-width: 600px){
  .MuiContainer-root {
      padding-left: 15px !important;
      padding-right: 15px !important;
  }
}