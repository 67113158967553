//BACKGROUND_COLOR
$BGBLACK: #000;
$BGWHITE: #ffffff;
$BGGREY: #C0C6CC;
$BGSECONDARY:#E8BCB1;
$BGPRIMARY_ONE:#3B4D61;
$BGPRIMARY_TWO:#F1F1F1;
$BG_OPACITY_05:rgba(232, 188, 177, 0.5);
$BGINPUT:#5F6E7E;
$BGLIGHTGREY: #F9F9FB;
$BGDARK: #223148;
$BODYBG: #F9F9FB;
$BGGREEN: #157575;


//TEXT_COLOR
$TXTBLACK: #000;
$TXTWHITE: #ffffff;
$TXTOFFWHITE: #C0C6CC;
$TXTPRIMARY_ONE:#3B4D61;
$TXTPRIMARY_TWO:#F1F1F1;
$TXTSECONDARY :#E8BCB1;
$TXTDISABLE:#C0C6CC;
$TXTYELLOW:#FFE600;
$TXTGRAY_MID:#A6A6A6;
$TXTGREEN:#27AE60;
$TXTORANGE:#FF8500;
$TXTRED:#FD4646;
$TXTDARK:#707070;
$TXTBLUE: #0D8EF8;
$TXTDARKGRAY:#6C6C6C;
$TXTGREYTEXTBOX: #4F4F4F;
$TXTGREEN2: #157575;
$TXTSELECT: #555770;

$BORDER_ERROR:#FFE600;
$BORDER_PRIMARY:#F1F1F1;
$BORDER_SECONDARY :#E8BCB1;
$BORDER:#B8B8B8;
$BORDER_WHITE:#FFFFFF;
$BORDER_GREY:#C0C6CC;
$BORDER_RED:#FF2525;
$BORDER_INPUT:#5F6E7E;
$BORDER_PRIM1:#3B4D61;


//FONT_SIZE
$FONT10: 10px;
$FONT12: 12px;
$FONT14: 14px;
$FONT15: 15px;
$FONT16: 16px;
$FONT18: 18px;
$FONT20: 20px;
$FONT22: 22px;
$FONT24: 24px;
$FONT26: 26px;
$FONT28: 28px;
$FONT30: 30px;
$FONT32: 32px;
$FONT48: 48px;

//FONT_FAMILY
$MONTSERRAT: 'Montserrat';
$OPENSANS: 'Opensans';