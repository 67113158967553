@import "../../assets/scss/variables.scss";

.MuiFormControl-root.customSelect {
  width: 100%;
  font-family: $OPENSANS;
  font-size: $FONT14;
  border-radius: 10px;
  border: 1px solid $BORDER_SECONDARY;
  background-color: $BGWHITE;
  line-height: 36px;
  box-sizing: border-box;
  height: 48px;
  margin: 0 0 16px 0;

  .MuiInput-underline:before,
  .MuiInput-underline:after {
    border: none !important;
  }

  .MuiModal-root {
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
  }

  .MuiPaper-elevation.MuiPaper-rounded {
    top: 25px !important;
    left: 0 !important;
    background: $BGPRIMARY_TWO;
    box-shadow: 0px -5px 10px rgba(84, 95, 122, 0.08),
      0px 24px 48px rgba(84, 95, 122, 0.1);
    border-radius: 10px;

    &::-webkit-scrollbar {
      width: 6px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #c6c6c6;
      border-radius: 5px;
    }

    ul {
      padding: 0px;

      li {
        .MuiListItemText-root {
          font-family: $OPENSANS;
          font-size: $FONT14;
          color: $TXTPRIMARY_ONE;
          padding: 8px 3px 10px;
          white-space: break-spaces;

        }
      }
    }
  }

  span.icon-plus-square2 {
    font-size: 19px;
  }

  .MuiSelect-select.MuiSelect-outlined {
    box-sizing: border-box;
    padding: 12px 16px;
    font-family: $OPENSANS;
    font-weight: 400;
    font-size: $FONT14;
    color: $TXTPRIMARY_ONE;
  }

  .addbtn-text {
    padding-left: 12px;
    font-family: $OPENSANS;
    font-style: normal;
    font-weight: 700;
    font-size: $FONT14;
    color: $TXTPRIMARY_ONE;
  }

  .input_addbtn {
    input {
      background: $BGWHITE;
      border: 1px solid $BORDER_SECONDARY;
      border-radius: 10px;
      padding: 13px 16px;
      font-size: $FONT14;
      font-family: $OPENSANS;
      min-width: 120px;
    }

    .textfiled_addbtn {
      background: $BGPRIMARY_ONE;
      border-radius: 10px;
      font-family: $OPENSANS;
      font-style: normal;
      font-weight: 800;
      font-size: $FONT14;
      text-align: center;
      color: $TXTWHITE;
      cursor: pointer;
      padding: 14px 30px;
      border: none;
      margin-left: 8px;
    }
  }

  .MuiInputBase-formControl.placeholder {
    .MuiSelect-select {
      color: $TXTOFFWHITE;
    }
  }

  .MuiMenu-list {
    li {
      font-family: $OPENSANS;
      font-size: $FONT14;
      color: $TXTPRIMARY_ONE;
      font-weight: 400;
      line-height: 30px;
      padding: 5px 16px;
      display: flex;
      justify-content: flex-start;

      &.disabled {
        color: $TXTOFFWHITE;
        pointer-events: none;

        span {
          color: $TXTOFFWHITE
        }
      }
    }
  }
}

.MuiFormControl-root.requiredSelect {
  .placeholder {
    .MuiSelect-select:after {
      content: "*";
      position: relative;
      right: 0;
      color: $TXTRED;
    }
  }
}

.MuiFormControl-root {
  &.requiredSelect {
    label.requiredField {
      position: absolute;
      left: 16px;
      top: 13px;
      margin: 0px;
      color: #c0c6cc;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      pointer-events: none;

      &:after {
        content: "*";
        color: #fd5d5d;
      }
    }

    .MuiMenu-list {
      li {
        line-height: 30px;
      }

      svg {
        color: $TXTPRIMARY_ONE;
      }
    }
  }

  &.customSelect {
    .MuiBox-root.selectBtn {
      padding-left: 17px;
      margin-bottom: 10px;
      cursor: pointer;

      span.icon-plus-square2 {
        top: 3px;
        position: relative;
      }
    }

    .input_addbtn {
      margin-top: 10px;
    }

    &.requiredSelect {
      .MuiSelect-select {
        max-width: 341px;
        padding-right: 40px;
      }
    }
  }

  &.withCheckBox {
    .MuiPaper-elevation.MuiPaper-rounded {
      .MuiMenu-list {
        li {
          padding: 0 0 0 5px;
          display: flex;
        }
      }
    }
  }
}

.MuiIconButton-root {
  color: $TXTPRIMARY_ONE;

  .MuiIconButton-label {
    &:before {
      content: "";
      width: 16px;
      height: 16px;
      border: 1px solid $BORDER_PRIM1;
      position: absolute;
      left: 12px;
      top: 12px;
      display: block;
      border-radius: 3px;
    }

    svg {
      color: $TXTPRIMARY_ONE;
      visibility: hidden;
    }
  }

  &.Mui-checked {
    &:before {
      border: none;
    }

    svg {
      visibility: visible;
    }
  }
}

li.MuiButtonBase-root.Mui-selected {
  .MuiIconButton-root {
    .MuiIconButton-label:before {
      content: "\e940";
      border: none;
      font-family: "icomoon";
      font-size: 18px;
      position: absolute;
      top: 5px;
      color: $TXTPRIMARY_ONE;
    }
  }
}

.selectedColorPalette {
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}

.selectColorBox {
  width: 54px;
  height: 30px;
  border-radius: 4px;
  margin-right: 3px;
  margin-bottom: 3px;
}