.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase:hover{
    background-color: transparent !important;
}
.switchTogglerWrapper{
    .MuiSwitch-sizeMedium {
        background: transparent;
        background: transparent;
        width: 27px !important;
        height: 13px !important;
        padding: 0px 10px !important;
        .MuiSwitch-switchBase {
            background: #E5E5E6;
            padding: 1px;
            border-radius: 100px;
            height: 13px;
            width: 26px !important;
            position: absolute;
            justify-content: flex-start;
            overflow: hidden;
            input{
                left: 0 !important;
                width: 100% !important;
            }
            span.MuiSwitch-thumb {
                background: #FFFFFF;
                box-shadow: 0px 3px 8px #00000026, 0px 3px 1px #0000000f;
                width: 9px !important;
                height: 9px !important;
                position: relative;
            }
            &.Mui-checked {
                background: #3B4D61 !important;
                padding: 1px;
                border-radius: 100px;
                height: 13px;
                width: 26.5px !important;
                position: absolute;
                justify-content: right;
                transform: translateX(0px) !important;
            }
        }
        
     
    }
    .MuiSwitch-sizeMedium .MuiSwitch-track {
        background: transparent;
    }
}

// .Mui-checked span.MuiSwitch-thumb {
//     right: 6px;
// }
