@import '../../assets/scss/variables.scss';

.customCheckBox {
    margin-bottom: 32px;
    .formControlLabel {
        span {
            padding: 0;
            &.Mui-checked{
                // color: red;
            }
            
            // .css-i4bv87-MuiSvgIcon-root{
            //     background-color: $BGBLACK;
            //     color: $TXTWHITE;
            //     width: 16px;
            //     height: 16px;
            // }
        }
        
    }
   
    
}
.MuiFormControlLabel-root{
    margin: 0;
    .MuiTypography-root{
        font-size: $FONT14;
        font-weight: 400;
        color: $TXTPRIMARY_TWO;
        font-family: $OPENSANS;
        padding-left: 4px;
    }
}
