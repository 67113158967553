@import "../../assets/scss/variables.scss";

.EarningsWrapper {
  &>&div>&div>.MuiGrid-item {
    padding-top: 0;
  }

  .mainBox {
    display: flex;
    align-items: center;

    .brushColor {
      width: 16px;
      height: 16px;
      border-radius: 2px;
      margin-right: 5px;
    }

    .productSize {
      font-weight: 400;
      font-size: $FONT12;
      font-family: $OPENSANS;
      color: $BGPRIMARY_ONE;
      padding-left: 5px;
      line-height: 18px;
    }

  }

  .secTitle.MuiTypography-root {
    font-size: $FONT14;
    line-height: 1.5;
    color: $TXTPRIMARY_ONE;
    margin-bottom: 8px;
    display: inline-block;
  }

  .produceEarnings {
    border-right: 1px solid $BORDER_GREY;
    padding-right: 8px !important;
  }

  .produceEarnings,
  .brandEarnings {
    width: 50%;
    padding-top: 0 !important;
    margin-bottom: 20px;
  }

  .brandEarnings {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    g.highcharts-legend-item.highcharts-pie-series {
      text {
        font-weight: normal !important;
        font-family: $OPENSANS;

        tspan:last-child {
          font-weight: 600;
        }
      }
    }

    text.highcharts-title {
      font-family: $OPENSANS;
      transform: translateX(10px);

      tspan:last-child {
        font-weight: 600;
        font-size: $FONT14;
      }
    }
  }

  .chartWrap {
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
      0px 0.5px 2px rgba(96, 97, 112, 0.16);
    border-radius: 8px;
    background-color: $BGWHITE;

    .timeFilterWrap,
    .dropDownWrap {
      display: flex;
      justify-content: space-between;
    }

    .dropDownWrap {
      padding: 16px;
      gap: 8px;

      .monthWrap {
        button.custom-date-input {
          width: 115px;
        }

        &.hideDisabled {
          display: none;
        }
      }

      .yearWrap {
        button.custom-date-input {
          width: 84px;
        }

        &.hideDisabled {
          display: none;
        }
      }

      .react-datepicker-wrapper {
        button.custom-date-input {
          background-color: transparent;
          border: 1px solid #dfe3e5;
          line-height: 26px;
          padding-top: 2px;
          display: flex;
          justify-content: space-between;
          padding-left: 16px;
          font-family: $OPENSANS;
          border-radius: 10px;

          i {
            line-height: 30px;
            width: 30px;
            position: absolute;
            top: 0px;
            right: -1px;
            color: $TXTSECONDARY;
            border-radius: 0 10px 10px 0;
            background-color: $BGPRIMARY_ONE;
            font-size: $FONT20;
          }
        }
      }
    }

    text.highcharts-credits {
      display: none;
    }

    rect.highcharts-legend-box {
      stroke: none;
    }

    path.highcharts-label-box.highcharts-tooltip-box {
      filter: drop-shadow(0px 10px 12px rgba(96, 97, 112, 0.14));
      stroke: none;
      fill: #fff;
    }

    g.highcharts-legend-item.highcharts-column-series {
      text {
        font-weight: 600 !important;
        font-family: $OPENSANS !important;

        tspan {
          font-weight: normal;
        }

        font-size: $FONT12;
      }
    }

    g.highcharts-axis-labels.highcharts-yaxis-labels {
      text {
        fill: $TXTPRIMARY_ONE !important;
      }
    }

    .radioWrap {
      .filterRadioButtonRow {
        display: flex;
        flex-direction: row;
        padding: 16px;

        .RadioButton {
          max-width: 72px;
          padding: 0;

          label {
            height: 30px;
            padding: 0 11px;
            width: auto;
            font-size: $FONT12;

            &:before {
              border-color: $BORDER_GREY;
              height: 28px;
            }
          }

          &:first-child {
            input[type="radio"] {
              &+label {
                border-radius: 10px 0 0 10px;

                &:before {
                  border-right: 0;
                  border-radius: 10px 0 0 10px;
                }
              }
            }
          }

          &:nth-child(2) {
            input[type="radio"] {
              &+label {
                border-radius: 0;

                &:before {
                  border-right: 0;
                  border-left: 0;
                  border-radius: 0;
                }
              }
            }
          }

          &:last-child {
            input[type="radio"] {
              &+label {
                border-radius: 0 10px 10px 0;

                &:before {
                  border-left: 0;
                  border-radius: 0 10px 10px 0;
                }
              }
            }
          }
        }
      }
    }
  }

  .tableWrap {
    margin-top: 15px;
    background-color: $BGWHITE;
    border-radius: 8px;
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
      0px 0.5px 2px rgba(96, 97, 112, 0.16);
    overflow: hidden;

    .MuiTableCell-root {
      font-family: $OPENSANS;
      font-size: $FONT12;
      border-right: 1px solid $BORDER_PRIMARY;
      color: $TXTPRIMARY_ONE;
      border-bottom: 0;
      line-height: 22px;
      padding: 15px;

      .brandTable {
        margin-top: 50px;
      }
    }

    th.MuiTableCell-head {
      background: $BGGREY;
      font-weight: 600;
      font-family: $OPENSANS;
      color: $TXTPRIMARY_ONE;
      font-size: $FONT14;
    }

    .tableFooterColumn {
      font-size: $FONT14;
      font-weight: 600;
    }

    tr.MuiTableRow-root:nth-child(even) {
      background: #f5f6f7;
    }
  }

  .react-datepicker {
    font-family: $OPENSANS;

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected,
    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range,
    .react-datepicker__day:hover,
    .react-datepicker__month-text:hover,
    .react-datepicker__quarter-text:hover,
    .react-datepicker__year-text:hover {
      background-color: $BGSECONDARY !important;
      color: $TXTPRIMARY_ONE !important;
      font-weight: normal;
    }

    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow,
    .react-datepicker__navigation-icon::before {
      border-color: $BORDER_SECONDARY !important;
      border-width: 2px 2px 0 0;
    }

    .react-datepicker__month .react-datepicker__month-text,
    .react-datepicker__month .react-datepicker__quarter-text,
    .react-datepicker__year .react-datepicker__year-text {
      width: 3rem;
      padding: 2px;
      font-family: "Opensans";
      color: $TXTWHITE;
    }

    .react-datepicker__year-wrapper {
      justify-content: space-around;
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
      background: $BGPRIMARY_ONE;
      color: $TXTWHITE;
      border: none;
    }

    .react-datepicker__year--container,
    .react-datepicker__month-container {
      background: $BGPRIMARY_ONE;
    }

    .react-datepicker__year {
      margin-bottom: 0;
      padding-bottom: 5px;
    }

  }

  .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle:after,
  .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle:before {
    border-bottom-color: $BGPRIMARY_ONE !important;
  }
}

@media (max-width: 1200px) {
  .EarningsWrapper {
    .tableWrap {
      th.MuiTableCell-head {
        padding: 0 7px;
        height: 52px;
      }
    }
  }
}

@media (max-width: 1023px) {
  .EarningsWrapper {

    .produceEarnings,
    .brandEarnings {
      width: 100%;
    }

    .produceEarnings {
      padding-right: 0;
      border: none;
    }
  }
}