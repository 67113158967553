@import "../../assets/scss/variables.scss";

.pageNotFoundMain{
    width:100%;
    background-color:$BGPRIMARY_ONE;
    &>div{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 30px;
    }
    .pageNotFound{
        align-self:center;
    }
    .pageNotFoundFooterTitle{
        font-size:150px;
        line-height: normal;
        color:$TXTWHITE;
        text-align: center;
        margin: 80px 0 24px;
        font-family: $MONTSERRAT;
        font-weight: 800;
    }
    .pageNotFoundFooterText{
        font-size:$FONT30;
        color: $TXTPRIMARY_TWO;
         font-family: $MONTSERRAT;
        font-weight: 400;
        max-width: 1100px;
        width: 100%;
        text-align: center;
    }
    .goBackButton{
        width: 335px;
        .btn{
            width: 100%;
            border: none;
            background-color: $BGSECONDARY;
            font-size: $FONT14;
            line-height: 21px;
            margin-top: 24px;
            color: $TXTPRIMARY_ONE;
            font-family: $OPENSANS;
            font-weight: 800;
            border-radius: 10px;
            height: 48px;
            margin-bottom: 30px;
            
        }
    }
}