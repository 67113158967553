@import '../../assets/scss/variables.scss';

.customLoadingBtnMain {
    .submitButton {
        background-color: $BGSECONDARY;
        font-family: $OPENSANS;
        line-height: 20px;
        padding: 14px 10px;
        border-radius: 0;
        box-shadow: none;
        transition: none;
        text-transform: initial;
        margin-bottom: 18px;
        font-weight: 800;
        font-size: $FONT14;
        color: $TXTPRIMARY_ONE;
        border-radius: 10px;
        margin-bottom: 4px;
        margin-top: 17px;

        &.variantOne {
            background-color: $BGWHITE;
            border: 1px solid $BGSECONDARY;
            color: $TXTBLACK;
        }
    }
    .css-180hkos-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root.Mui-disabled {
        pointer-events: all;
        cursor: not-allowed;
    }
    .submitButton.Mui-disabled {
        background-color: $BG_OPACITY_05;
        color: $TXTWHITE;
        cursor: not-allowed;
        pointer-events: all;
        &:hover {
            background-color: $BG_OPACITY_05;
        }
    }
    .submitButton {
        &:hover {
            background-color:$TXTSECONDARY;
        }
    }

    .submitButton.MuiLoadingButton-loading {
        background-color: $BGSECONDARY;
        color: transparent;

        .css-1yt7yx7-MuiLoadingButton-loadingIndicator {
            color: $TXTPRIMARY_ONE;
        }
    }
}