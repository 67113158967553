@import "../../../assets/scss/variables.scss";

.dashboard {
    width: 100%;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 0px;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 0px;
        background-color: rgba(0, 0, 0, 0.2);
      }

    .dashboardWrapper {
        width: 100%;

        .dashboardContainer {
            width: 100%;
            max-width: 1295px;
            padding: 0 15px;
            margin: 0 auto;
            

            .sectionTitle {
                .title {
                    font-family: $OPENSANS;
                    font-weight: 700;
                    font-size: $FONT18;
                    line-height: 27px;
                    color: $TXTPRIMARY_ONE;
                }
                padding: 24px 0px;
            }

            .dashboardProductWrapper {
                overflow: auto;
               
                .ProductView {
                    padding: 0px;
                    display: grid;
                    grid-template-columns: 255px 255px 255px;
                    height: auto !important;
                    background: transparent;
                    box-shadow: 0px 0px;
                    grid-gap: 24px;

                    .ProductMain {
                        padding: 10px !important;
                        grid-template-columns: 90px 1fr;
                        grid-gap: 16px;
                        background: #FFFFFF;
                        box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
                        border-radius: 8px;
                        display: grid;

                        .ProductIcon {
                            background: $BGPRIMARY_ONE;
                            width: 90px;
                            height: 90px;
                            margin: 0px;
                            border-radius: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            i {
                                font-size: 43px;
                                color: #E8BCB1;
                            }
                        }

                        .ProductContentWrapper {
                            margin-bottom: 0px;
                            display: flex;
                            align-items: baseline;
                            flex-direction: column;
                            justify-content: center;

                            p {
                                font-size: $FONT16;
                                color: $BGPRIMARY_ONE;
                                margin: 0px;
                                font-weight: 600;
                                align-items: left;
                            }

                            h2 {
                                font-size: $FONT30;
                                font-weight: 400;
                            }
                        }

                        .ProductBorder {
                            display: none;
                        }
                    }
                }
            }

            .dashboardContentWrapper {
                overflow: hidden;
               

                .dashboardrecentorder {
                    .recentToporder {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 16px;

                        .sectionTitle {
                            padding: 0px;
                        }

                        .Linkredirectviewall {
                            color: $TXTPRIMARY_ONE;
                            text-transform: capitalize;
                            font-weight: 400;
                            font-size: $FONT14;
                            line-height: 1.5;
                            font-family: $OPENSANS;
                        }
                    }
               
                }

                .chartsection {
                    padding: 16px 0px 0px 0px;
                    // box-shadow: 0px 24px 48px rgba(84, 95, 122, 0.1);
                    border-radius: 8px;
                    overflow: hidden;
                    background: $BGWHITE;
                    margin: 15px 0px;

                    .chartHeader {
                        display: grid;
                        grid-template-columns: 4fr 8fr;
                        padding: 0px 16px;

                        .chartTilte {
                            .title {
                                font-weight: 400;
                                font-size: $FONT14;
                                color: $TXTDARK;
                                
                            }

                        }

                        .chartTabAndDateWrapper {
                            display: grid;
                            grid-template-columns: 1fr auto;
                            grid-gap: 24px;

                            .radioWrap {
                                .filterRadioButtonRow {
                                    display: flex;
                                    flex-direction: row;
                                    width: fit-content;
                                    min-width: 215px;
                                    border: 1px solid #dfe3e5;
                                    border-radius: 8px;
                                    overflow: hidden;
                                    margin-left: auto;

                                    .RadioButton {
                                        padding: 0;

                                        img {
                                            display: none;
                                        }

                                        label {
                                            height: 30px;
                                            padding: 0px;
                                            width: 100%;
                                            font-size: $FONT12;
                                            border-radius: 0px !important;
                                            font-weight: 400;
                                            color: #3B4D61;

                                            &:before {
                                                border-color: $BORDER_GREY;
                                                height: auto;
                                                border: 0px !important;
                                            }
                                        }

                                    }
                                    .RadioButton input[type="radio"]:checked + label{
                                        color: #fff;
                                    }
                                }
                            }

                            .dropDownWrap {
                                display: flex;
                                justify-content: space-between;
                            }

                            .dropDownWrap {
                                grid-gap: 24px;

                                .monthWrap {
                                    button.custom-date-input {
                                        width: 115px;
                                        color: #3B4D61;
                                    }
                                    &.hideDisabled {
                                        display: none;
                                    }
                                }

                                .yearWrap {
                                    button.custom-date-input {
                                        width: 115px;
                                        color: #3B4D61;
                                    }
                                    &.hideDisabled {
                                        display: none;
                                    }
                                }

                                .react-datepicker-wrapper {
                                    border: 1px solid #dfe3e5;
                                    border-radius: 8px;
                                    overflow: hidden;
                                    height: 30px;
                                    display: flex;
                                    align-items: center;

                                    .react-datepicker__input-container {
                                        height: 100%;
                                        display: flex;
                                        align-items: center;
                                    }

                                    button.custom-date-input {
                                        background-color: transparent;
                                        display: flex;
                                        justify-content: space-between;
                                        font-family: $OPENSANS;
                                        border: 0px;
                                        height: 100%;
                                        padding: 0px;
                                        display: flex;
                                        align-items: center;
                                        padding: 0px 10px;

                                        i {
                                            line-height: 30px;
                                            width: 30px;
                                            position: absolute;
                                            top: 0px;
                                            right: -1px;
                                            color: $TXTSECONDARY;
                                            // border-radius: 0 10px 10px 0;
                                            background-color: $BGPRIMARY_ONE;
                                            font-size: $FONT20;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .highcharts-container{
                        margin-top: 48px;
                    }
                    .highcharts-no-tooltip {
                        display: none;
                    }
                    .highcharts-title{
                        display: none;
                    }
                    .highcharts-yaxis-labels{
                        text {
                            font-weight: 500 !important;
                            font-size: 12px !important;
                            line-height: 18px;
                            color: #707070 !important;
                            font-family: $OPENSANS !important;

                        }
                    }
                                     
                    .highcharts-tooltip text {
                        fill: white;
                        font-family: 'Opensans'
                    }
                    .highcharts-credits{
                        display: none;
                    }
                    .highcharts-xaxis-labels {
                        text {
                            font-weight: 400 !important;
                            font-size: 12px !important;
                            line-height: 18px;
                            color: #3B4D61 !important;
                            font-family: $OPENSANS !important;

                        }
                    }
                }
            }
            .platform-wrapper{
                display: flex;
                align-items: center;
                .color-define {
                    display: flex;
                    align-items: center;
                    grid-gap: 18px;
                    margin-left: 30px;
                    .color-box-wrapper {
                        display: flex;
                        grid-gap: 8px;
                        align-items: center;
                        .box {
                            width: 13px;
                            height: 13px;
                            border-radius: 2px;
                        }
                        .color-Define-title{
                            font-weight: 400;
                            font-size: 10px;
                            line-height: 15px;
                            color: #707070;
                        }
                        &.color-User{
                            .box{
                                background:#C0C6CC;
                            }
                        }
                        &.color-Subscribed{
                            .box{
                                background:#E8BCB1;
                            }
                        }
                        &.color-Onetimebuy{
                            .box{
                                background:#3B4D61;
                            }
                        }
                    }
                }
                
                
            }
        }
    }

    .react-datepicker {
        font-family: $OPENSANS;

        .react-datepicker__day--keyboard-selected,
        .react-datepicker__month-text--keyboard-selected,
        .react-datepicker__quarter-text--keyboard-selected,
        .react-datepicker__year-text--keyboard-selected,
        .react-datepicker__day--selected,
        .react-datepicker__day--in-selecting-range,
        .react-datepicker__day--in-range,
        .react-datepicker__month-text--selected,
        .react-datepicker__month-text--in-selecting-range,
        .react-datepicker__month-text--in-range,
        .react-datepicker__quarter-text--selected,
        .react-datepicker__quarter-text--in-selecting-range,
        .react-datepicker__quarter-text--in-range,
        .react-datepicker__year-text--selected,
        .react-datepicker__year-text--in-selecting-range,
        .react-datepicker__year-text--in-range,
        .react-datepicker__day:hover,
        .react-datepicker__month-text:hover,
        .react-datepicker__quarter-text:hover,
        .react-datepicker__year-text:hover {
            background-color: $BGSECONDARY !important;
            color: $TXTPRIMARY_ONE !important;
            font-weight: normal;
        }

        .react-datepicker__year-read-view--down-arrow,
        .react-datepicker__month-read-view--down-arrow,
        .react-datepicker__month-year-read-view--down-arrow,
        .react-datepicker__navigation-icon::before {
            border-color: $BORDER_SECONDARY !important;
            border-width: 2px 2px 0 0;
        }

        .react-datepicker__month .react-datepicker__month-text,
        .react-datepicker__month .react-datepicker__quarter-text,
        .react-datepicker__year .react-datepicker__year-text {
            width: 3rem;
            padding: 2px;
            font-family: "Opensans";
            color: $TXTWHITE;
        }

        .react-datepicker__year-wrapper {
            justify-content: space-around;
        }

        .react-datepicker__current-month,
        .react-datepicker-time__header,
        .react-datepicker-year-header {
            background: $BGPRIMARY_ONE;
            color: $TXTWHITE;
            border: none;
        }

        .react-datepicker__year--container,
        .react-datepicker__month-container {
            background: $BGPRIMARY_ONE;
        }

        .react-datepicker__year {
            margin-bottom: 0;
            padding-bottom: 5px;
        }

    }

    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle:after,
    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle:before {
        border-bottom-color: $BGPRIMARY_ONE !important;
    }
}

// 1440 768
@media only screen and (min-width: 992px) and (max-width: 1440px) {
    .dashboard {
        padding: 31px 0px;
        .dashboardWrapper {
            .dashboardContainer {
                width: 100%;
                max-width: 1295px;

                .dashboardProductWrapper {
                    display: grid;
                    grid-template-columns: 1fr;
                    

                    .ProductView {
                        padding: 0px;
                        display: grid;
                        grid-template-columns: 255px 255px 255px;
                        height: auto !important;
                        background: transparent;
                        box-shadow: 0px 0px;
                        grid-gap: 24px;
                        padding-bottom: 16px;


                    .ProductMain {
                            padding: 10px !important;
                            grid-template-columns: 90px 1fr;
                            grid-gap: 16px;
                            background: #FFFFFF;
                            box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
                            border-radius: 8px;
                            display: grid;

                            .ProductIcon {
                                background: $BGPRIMARY_ONE;
                                width: 90px;
                                height: 90px;
                                margin: 0px;

                                i {
                                    font-size: 43px;
                                }
                            }

                            .ProductContentWrapper {
                                margin-bottom: 0px;
                                display: flex;
                                align-items: baseline;
                                flex-direction: column;
                                justify-content: center;

                                p {
                                    font-size: $FONT16;
                                    color: $BGPRIMARY_ONE;
                                    margin: 0px;
                                    font-weight: 600;
                                    align-items: left;
                                }

                                h2 {
                                    color: $BGPRIMARY_ONE;
                                    font-size: $FONT30;
                                    font-weight: 400;
                                    align-items: left;
                                }
                            }

                            .ProductBorder {
                                display: none;
                            }
                        }
                    }
                    .dashboardContentWrapper{
                        padding: 0px;
                        .chartsection {
                            .chartHeader {
                                display: grid;
                                grid-template-columns: 250px 8fr;
                            }
                           .chartTabAndDateWrapper{
                            .dropDownWrap {
                                .monthWrap{
                                    button.custom-date-input{
                                        width: 80px;
                                    }
                                    &.hideDisabled {
                                        display: none;
                                    }
                                }
                                .yearWrap{
                                    button.custom-date-input{
                                        width: 80px;
                                    }
                                    &.hideDisabled {
                                        display: none;
                                    }
                                }
                            }
                           }
                        }
                        
                        .platform-wrapper{
                            text-align: unset !important;
                            display: flow-root;
                            .title{
                                margin-bottom: 10px;
                            }
                            .color-define {
                                margin-left: 0px !important;
                            }
                        }
                    }
                  
                }
               
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .dashboard {
        
        .dashboardWrapper {
            .dashboardContainer {
                width: 100%;
                max-width: 1295px;

                .dashboardProductWrapper {
                    display: grid;
                    grid-template-columns: 1fr;

                    .ProductView {
                        padding: 0px;
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr;
                        height: auto !important;
                        background: transparent;
                        box-shadow: 0px 0px;
                        grid-gap: 16px;
                        padding-bottom: 16px;
                        .ProductMain {
                            padding: 10px !important;
                            grid-template-columns: 60px 1fr;
                            grid-gap: 16px;
                            background: #FFFFFF;
                            box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
                            border-radius: 8px;
                            display: grid;

                            .ProductIcon {
                                background: $BGPRIMARY_ONE;
                                width: 60px;
                                height: 60px;
                                margin: 0px;

                                i {
                                    font-size: 30px;
                                }

                                img {
                                    max-width: 50%;
                                }
                            }

                            .ProductContentWrapper {
                                margin-bottom: 0px;
                                display: flex;
                                align-items: baseline;
                                flex-direction: column;
                                justify-content: center;

                                p {
                                    font-size: $FONT16;
                                    color: $BGPRIMARY_ONE;
                                    margin: 0px;
                                    font-weight: 600;
                                    align-items: left;
                                }

                                h2 {
                                    color: $BGPRIMARY_ONE;
                                    font-size: $FONT24;
                                    font-weight: 400;
                                    align-items: left;
                                }
                            }

                            .ProductBorder {
                                display: none;
                            }
                        }
                    }
                    .dashboardContentWrapper{
                        padding: 0px;
                        .chartsection {
                            .chartHeader {
                                display: grid;
                                grid-template-columns: 250px 8fr;
                            }
                           .chartTabAndDateWrapper{
                            .dropDownWrap {
                                .monthWrap{
                                    button.custom-date-input{
                                        width: 80px;
                                    }
                                    &.hideDisabled {
                                        display: none;
                                    }
                                }
                                .yearWrap{
                                    button.custom-date-input{
                                        width: 80px;
                                    }
                                    &.hideDisabled {
                                        display: none;
                                    }
                                }
                            }
                           }
                        }
                        
                        .platform-wrapper{
                            text-align: unset !important;
                            display: flow-root;
                            .title{
                                margin-bottom: 10px;
                            }
                            .color-define {
                                margin-left: 0px !important;
                            }
                        }
                    }
                }
            }
        }
       
    }
}