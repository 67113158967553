@import "../../assets/scss/variables.scss";
.videoList {
  margin-top: 17px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  .videoCard.MuiPaper-root {
    display: flex;
    flex-direction: column;
    width: 23.9%;
    max-width: 100%;
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
    border-radius: 8px;
    .rightWrap {
      width: 100%;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      padding-right: 8px;
      .cardBottom {
        padding-bottom: 0;
        align-items: flex-start;
        .smallTitle {
          font-family: $OPENSANS;
          font-weight: 400;
          font-size: $FONT10;
          line-height: 15px;
          margin-bottom: 4px;
          color: $TXTPRIMARY_ONE;
        }
        .category {
          margin-bottom: 0;
        }
        & > div {
          & > div {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: $TXTPRIMARY_ONE;
            max-width: 70px;
            font-family: $OPENSANS;
            word-wrap: break-word;
          }
        }
        .statusText {
          border-radius: 10px;
          padding: 0 11px;
        }
        .active {
          .statusText {
            border: 1px solid #e8bcb1;
          }
        }
        .inactive {
          .statusText {
            border: 1px solid $TXTOFFWHITE;
            color: $TXTOFFWHITE;
          }
        }
        .rightSide {
          padding-top: 6px;
        }
      }
    }
    .rightWrap.withoutImage {
      flex-direction: row;
      padding: 11px 6px 10px 16px;
      .rightSide {
        margin-left: 0;
      }
    }
    .imageWrapper {
      padding: 8px 8px 3px;
      position: relative;
      cursor: pointer;
      video,iframe{
        border-radius: 10px;
        object-fit: cover;
      }
      img {
        width: 100%;
        height: 104px;
        border-radius: 10px;
      }
      .icon-play-filled {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 40px;
      }
    }
    .cardContent {
      padding: 8px;
      color: $TXTPRIMARY_ONE;
      justify-content: flex-start;
      .category {
        margin: 0;
        font-size: 12px;
        line-height: 18px;
        font-family: $OPENSANS;
      }
      .title {
        font-weight: 600;
        font-size: 14px;
        font-family: $OPENSANS;
        padding-bottom: 8px;
        border-bottom: 1px solid $BORDER_GREY;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .statusBox {
        span {
          margin-right: 7px;
          padding: 0px 11px;
          font-size: 12px;
          line-height: 18px;
          display: inline-block;
          border-radius: 10px;
          font-family: $OPENSANS;
        }
        .active {
          border: 1px solid #e8bcb1;
        }
        .inactive {
          color: $TXTOFFWHITE;
          border: 1px solid #c0c6cc;
        }
        .subscribed {
          background: #e8bcb1;
          border-radius: 10px;
        }
      }
    }
    .MuiCardActions-root {
      justify-content: space-between;
      padding-left: 0;
    }
    .rightSide {
      visibility: hidden;
      margin-left: 4px;
      button {
        font-size: 12px;
        line-height: 18px;
        display: inline;
        min-width: auto;
        text-transform: initial;
        padding: 0 0 0 8px;
        color: $TXTPRIMARY_ONE;
        font-family: $OPENSANS;
        &.deleteBtn {
          color: $TXTRED;
        }
      }
      .MuiSwitch-switchBase {
        padding: 0;
        position: relative;
      }
      .MuiButtonBase-root {
        .MuiSwitch-input {
        }
        .MuiSwitch-thumb {
          position: relative;
          top: 0;
          left: 12px;
          width: 10px;
          height: 10px;
          color: $TXTWHITE;
        }
        &.Mui-checked {
          .MuiSwitch-thumb {
            left: 3px;
          }
        }
      }
      .MuiSwitch-switchBase.Mui-checked {
        & + .MuiSwitch-track {
          opacity: 1;
          background-color: $BGPRIMARY_ONE;
        }
      }
      .MuiSwitch-track {
        height: 14px;
        width: 25.46px;
      }
      .MuiSwitch-root {
        width: 36px;
        padding: 0;
        height: auto;
        margin-left: 0;
        justify-content: flex-end;
      }
    }
    &:hover {
      box-shadow: 0px 24px 48px rgba(84, 95, 122, 0.1);
      .rightSide {
        visibility: visible;
      }
    }
  }
}
@media (max-width: 1150px) {
  .manageProducts {
    .cardList {
    }
  }
}
@media (min-width: 1280px) and (max-width: 1439px) {
  .videoList {
    .videoCard.MuiPaper-root {
      .rightWrap {
        .rightSide {
          margin-left: 4px;
        }
        .cardBottom {
          padding-right: 0;
          .statusText {
            padding: 0 8px;
          }
          .MuiTypography-root.MuiTypography-subtitle2 {
            &:nth-child(2) {
              margin-left: 3px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .videoCard.MuiPaper-root {
    .rightWrap {
      .cardBottom {
        .rightSide {
          visibility: visible;
        }
      }
    }
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .videoList {
    .videoCard.MuiPaper-root {
      width: calc(33.333% - 16px);
    }
  }
}
@media (max-width: 1023px) {
  .videoList {
    .videoCard.MuiPaper-root {
      width: calc(50% - 16px);
    }
  }
}
