@import "../../assets/scss/variables.scss";

.ColorWrap {
  display: flex;
  flex-direction: column;
}

.cardList {
  margin-top: 17px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;


  .rightWrap.withoutImage {
    .cardContent {
      max-width: 140px;
      padding-top: 0;
    }
  }

  .colorBoxes {
    display: flex;
    flex-direction: row;
    margin: 0px 10px 10px 10px;
    flex-wrap: wrap;

    .colorBox {
      width: 24px;
      height: 24px;
      margin-right: 3px;
    }
  }
}

.cardWrap.MuiPaper-root {
  display: flex;
  align-items: flex-start;
  width: 32.33%;
  max-width: 100%;
  box-shadow: none;
  border-radius: 8px;
  // overflow: visible;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
    0px 0.5px 2px rgba(96, 97, 112, 0.16);

  .rightWrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding-right: 8px;
    padding-left: 4px;
  }

  .rightWrap.withoutImage {
    flex-direction: row;
    padding: 11px 6px 10px 10px;

    .rightSide {
      margin-left: 0;
    }
  }

  img {
    width: 100%;
    height: auto;
    max-width: 115px;
    padding: 6px;
    box-sizing: content-box;
  }

  .cardContent {
    padding: 8px 0 0;
    color: $TXTPRIMARY_ONE;

    .category {
      margin: 0;
      font-size: 12px;
      line-height: 18px;
      font-family: $OPENSANS;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .mainBrushBox {
      display: flex;
      align-items: center;

      .brushColor {
        width: 16px;
        height: 16px;
        border-radius: 2px;
        margin-right: 5px;
      }

      .title {
        font-weight: 600;
        font-size: 14px;
        font-family: $OPENSANS;
        line-height: 1.5;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }


    .statusBox {
      margin-top: 3px;

      span {
        margin-right: 7px;
        padding: 0px 11px;
        font-size: 12px;
        line-height: 18px;
        display: inline-block;
        border-radius: 10px;
        font-family: $OPENSANS;

        &.hide {
          display: none;
        }
      }

      .active {
        border: 1px solid #e8bcb1;
      }

      .inactive {
        color: $TXTOFFWHITE;
        border: 1px solid #c0c6cc;
      }

      .subscribed {
        background: #e8bcb1;
        border-radius: 10px;
      }

      .brushSize {
        padding: 0px;
        font-family: $OPENSANS;
        font-size: $FONT12;
        font-weight: 400;
        color: $BGPRIMARY_ONE;
      }
    }
  }

  .MuiCardActions-root {
    justify-content: space-between;
    padding: 0 0 7px 0;

    .leftSide {
      p {
        font-size: $FONT16;
        font-weight: 400;
        font-family: $OPENSANS;
      }
    }
  }

  .rightSide {
    visibility: hidden;
    width: 115px;

    button {
      font-size: 12px;
      line-height: 18px;
      display: inline;
      min-width: auto;
      text-transform: initial;
      padding: 0 0 0 8px;
      color: $TXTPRIMARY_ONE;
      font-family: $OPENSANS;
      background-color: transparent;

      &.deleteBtn {
        color: $TXTRED;
      }
    }

    .MuiSwitch-switchBase {
      padding: 0;
      position: relative;
    }

    .MuiButtonBase-root {
      .MuiSwitch-input {}

      .MuiSwitch-thumb {
        position: relative;
        top: 0;
        left: 12px;
        width: 10px;
        height: 10px;
        color: $TXTWHITE;
      }

      &.Mui-checked {
        .MuiSwitch-thumb {
          left: 3px;
        }
      }
    }

    .MuiSwitch-switchBase.Mui-checked {
      &+.MuiSwitch-track {
        opacity: 1;
        background-color: $BGPRIMARY_ONE;
      }
    }

    .MuiSwitch-track {
      height: 14px;
      width: 25.46px;
    }

    .MuiSwitch-root {
      width: 36px;
      padding: 0;
      height: auto;
      margin-left: 0;
      justify-content: flex-end;
    }
  }

  &:hover {
    box-shadow: 0px 24px 48px rgba(84, 95, 122, 0.1);

    .rightSide {
      visibility: visible;
    }
  }
}

.brandList {
  &.cardList {
    .cardWrap {
      &.MuiPaper-root {
        width: calc(25% - 16px);
      }
    }
  }
}

@media (max-width: 1160px) {
  .manageProducts {
    .cardList {
      .cardWrap {
        width: calc(33.333% - 16px);
      }
    }
  }

  .brandList {
    &.cardList {
      .cardWrap {
        &.MuiPaper-root {
          width: calc(25% - 16px);
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .cardList {
    .rightWrap.withoutImage {
      .cardContent {
        max-width: 93px;
      }
    }
  }
}

@media (max-width: 1065px) {
  .cardWrap.MuiPaper-root {
    .rightWrap {
      &.withoutImage {
        padding-left: 12px;
      }
    }
  }
}

@media (max-width: 1023px) {
  .brandList {
    &.cardList {
      .cardWrap {
        &.MuiPaper-root {
          width: calc(33.333% - 16px);
        }
      }
    }
  }
}

@media (max-width: 980px) {
  .manageProducts {
    .cardList {
      .cardWrap {
        width: calc(50% - 16px);
      }
    }

    .brandList.cardList {
      .cardWrap {
        width: calc(33.333% - 16px);

        .rightWrap.withoutImage {
          padding: 10px 6px 10px 14px;

          .rightSide {
            margin-left: 0;

            .MuiSwitch-track {
              width: 20px;
            }

            .MuiButtonBase-root {
              .MuiSwitch-thumb {
                left: 9px;
                width: 7px;
              }

              &.Mui-checked {
                .MuiSwitch-thumb {
                  left: -1px;
                  width: 7px;
                }
              }
            }

            .MuiSwitch-root {
              margin-left: 0;
            }

            button:first-child {
              padding-left: 0;
            }
          }
        }

        .MuiCardActions-root {
          padding: 0 0 0 0;
        }

        .cardContent {
          max-width: 85px;
        }
      }
    }
  }
}