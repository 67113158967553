@import "../../assets/scss/variables.scss";
.addPromoCodeFormCard {
  .Mui-checked {
    position: relative;
    path {
      display: none;
    }
    &::before {
      content: "\e93e";
      position: absolute;
      font-size: 18px;
      font-family: "icomoon";
      color: $BGPRIMARY_ONE;
    }
  }
  .PrivateSwitchBase-input {
    width: 14px;
    height: 14px;
  }
  .RadioButton {
    background: $BGWHITE;
    border-radius: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 14px 16px;
    justify-content: flex-start;
    .MuiTypography-root {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: $BGPRIMARY_ONE;
    }
    .MuiButtonBase-root {
      padding: 0px;
      margin-left: auto;
      .MuiSvgIcon-root {
        width: 16px;
        height: 16px;
        // border: 1px solid #C0C6CC;
        border-radius: 50%;
        fill: $TXTOFFWHITE;
      }
    }
    i {
      font-size: 16px;
      color: $TXTOFFWHITE;
      margin-right: 8px;
      &.icon-subscribe {
        color: $TXTSECONDARY;
      }
    }
  }
}
