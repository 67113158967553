@import "../../assets/scss/variables.scss";

.smallPopup {
    .modalWrap{
        padding: 0;
    }
  .MuiBox-root {
    background-color: $BGPRIMARY_ONE;
    box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08),
      0px 20px 32px rgba(96, 97, 112, 0.24);
    border-radius: 10px !important;
    border: none;
    text-align: center;
    max-width: 256px;
    
    .icon-warning {
      font-size: 77px;
      color: $TXTSECONDARY;
      margin-top: 24px;
      margin-bottom: 18px;
      display: block;
    }
    h2 {
      color: $TXTYELLOW;
      margin-bottom: 8px;
      font-weight: 600;
      font-size: $FONT16;
      font-family: $OPENSANS;
    }
    p{
        font-family: $OPENSANS;
        font-weight: 400;
        font-size: $FONT12;
        color: $TXTWHITE;
        line-height: 18px;
        word-break: break-word;
    }
    .btnWrap{
        padding: 19px 0;
        border-top: 1px solid rgba(241,241,241,0.3  );
        border-radius: 0;
        margin-top: 6.5px;
    button{ 
        font-size: $FONT12;
        font-family: $OPENSANS;
        text-transform: initial;
        padding: 8.5px 14px;
        min-width: 108px ;
        font-weight: 400;
        border: 1px solid transparent;
        border-radius: 5px;
        &:first-child{
            color: $TXTWHITE;
            border-color: $BGSECONDARY;
            margin-right: 8px;

        }
        &:last-child{
            background-color: $BGSECONDARY;
            color: $TXTPRIMARY_ONE;
        }
    }
}
  }
}
