@import "../../assets/scss/variables.scss";

.viewOrderWrapper {
  width: 100%;
  background-color: $BODYBG;
  height: 100%;

  .viewOrderContainer {
    max-width: 1295px;
    width: 100%;
    margin: 0 auto;
    padding: 0px 15px;

    .titleSection {
      margin-top: 24px;

      .title {
        font-weight: 700;
        font-size: $FONT18;
        line-height: 27px;
        color: $TXTPRIMARY_ONE;
        font-family: $OPENSANS;
      }
    }

    .searchTopSection {
      .searchBoxDiv {
        width: 461px;

        .searchInput {
          width: 100%;
        }
      }
    }

    .filterSection {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 20px;

      .filterDataTitle {
        margin-right: 10px;

        .title {
          font-weight: 600;
          font-size: $FONT14;
          line-height: 21px;
          color: $TXTPRIMARY_ONE;
          font-family: $OPENSANS;
        }
      }

      .filterDropRow {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 100%;
        justify-content: flex-end;

        .filterDropDownColumn {
          max-width: 224px;
          width: 100%;

          &:not(:last-child) {
            margin-right: 10px;
          }

          > div {
            .customSelect {
              height: auto;
              margin: 0px;
              max-width: 224px;
              width: 100%;

              &.MuiFormControl-root {
                .icon-chevron-down {
                  right: 12px;
                  top: 6px;
                }
              }

              .MuiInputBase-root {
                border-radius: 10px;
                position: relative;

                .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
                  display: none;

                  .css-14lo706 {
                    display: none;
                  }
                }

                .MuiSelect-outlined {
                  padding: 8.5px 30px 8.5px 16px;

                  &::placeholder {
                    font-weight: 400;
                    font-size: $FONT14;
                    color: $TXTPRIMARY_ONE;
                    font-family: $OPENSANS;
                  }
                }
              }
            }
          }
        }
      }
    }

    .viewOrderListing {
      margin-top: 24px;
      border-radius: 10px;
      overflow: hidden;

      .table {
        .tableHeader {
          background: #3b4d61;

          .tableHeaderRow {
            .tableHeaderColumn {
              font-weight: 600;
              font-size: $FONT14;
              line-height: 20px;
              color: #f1f1f1;
              background: $BGPRIMARY_ONE;
              width: 25%;
              font-family: $OPENSANS;
              padding: 16px 12px 15px;
            }
          }
        }

        .tableBody {
          .tableBodyRow {
            background: $BGWHITE;
            cursor: pointer;

            &:not(:last-child) {
              box-shadow: 0px 24px 48px rgba(84, 95, 122, 0.1);
            }

            .tableBodyColumn {
              font-weight: 400;
              font-size: $FONT14;
              line-height: 21px;
              color: $TXTPRIMARY_ONE;
              font-family: $OPENSANS;
              padding: 15.5px 12px;
              border-bottom: 1px solid #eff0f2;

              .orderByName {
                display: flex;
                align-items: center;

                .shortName {
                  width: 32px;
                  height: 32px;
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 18px;
                  color: #ffffff;
                  background-color: $TXTPRIMARY_ONE;
                  margin-right: 10px;
                  margin-left: 4px;
                  font-family: $OPENSANS;
                }

                .fullName {
                  font-weight: 400;
                  font-size: $FONT14;
                  line-height: 21px;
                  color: $TXTPRIMARY_ONE;
                  font-family: $OPENSANS;
                }
              }
            }
          }
        }
      }
    }
  }

  .MuiPopover-root {
    .MuiPaper-root.MuiMenu-paper {
      max-width: 224px;
      width: 100%;
      background: #f1f1f1;
      box-shadow: 0px -5px 10px rgba(84, 95, 122, 0.08),
        0px 24px 48px rgba(84, 95, 122, 0.1);
      border-radius: 10px;
      // left: 52px !important;
      // top: 200px !important;
      padding: 5px 0;
      top: 21px !important;

      .MuiList-root {
        padding: 0px;

        .MuiMenuItem-root {
          margin-bottom: 0;
          width: 100%;
          font-weight: 400;
          font-size: $FONT14;
          line-height: 21px;
          color: #555770;
          font-family: $OPENSANS;

          &:last-child {
            margin-bottom: 0px;
          }

          &.Mui-selected {
            .MuiIconButton-root {
              .MuiIconButton-label:before {
                top: 11px;
              }
            }
          }

          .MuiListItemText-root {
            .MuiTypography-root {
              font-size: $FONT14;
              font-weight: 400;
              color: #555770;
              font-family: $OPENSANS;
              line-height: 20px;
              word-break: break-word;
            }
          }

          .checkBoxCustom {
            padding: 0px;
            margin-right: 8px;

            &.MuiIconButton-root {
              color: $TXTPRIMARY_ONE;

              .MuiIconButton-label {
                &:before {
                  content: "";
                  width: 16px;
                  height: 16px;
                  border: 1px solid #3b4d61;
                  position: absolute;
                  left: 3px;
                  top: 3px;
                  display: block;
                  border-radius: 3px;
                }

                svg {
                  color: $TXTPRIMARY_ONE;
                  visibility: hidden;
                }
              }

              &.Mui-checked {
                &:before {
                  border: none;
                }

                svg {
                  visibility: visible;
                }
              }
            }
          }

          .icon-chevron-down {
            top: 5px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1281px) and (max-width: 1440px) {
  .viewOrderWrapper {
    .viewOrderContainer {
      max-width: 1200px;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .viewOrderWrapper {
    .viewOrderContainer {
      .filterSection {
        .filterDropRow {
          justify-content: flex-start;

          .filterDropDownColumn {
            max-width: 182px;
            width: 100%;

            &:last-child {
              margin-right: 0px;
            }

            > div {
              .customSelect {
                width: 100%;
                max-width: 100%;
              }
            }
          }
        }
      }

      .viewOrderListing {
        .table {
          .tableHeader {
            .tableHeaderRow {
              .tableHeaderColumn {
                width: 26%;
              }
            }
          }
        }
      }
    }
  }
}