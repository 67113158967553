@import "../../assets/scss/variables.scss";

.userManageMent {
  width: 100%;
  background: $BGLIGHTGREY;

  .userManagermentWrapper {
    padding-top: 24px;
    width: 100%;

    .userManagementCountainer {
      width: 100%;
      max-width: 1295px;
      padding: 0 15px;
      margin: 0 auto;

      .sectionTitle {
        .title {
          font-family: $OPENSANS;
          font-weight: 700;
          font-size: $FONT18;
          line-height: 27px;
          color: $TXTPRIMARY_ONE;
        }
      }

      .customSearchCardRow {
        margin-top: 23px;

        .customSearchCard {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;

          .searchBoxDiv {
            width: 100%;
            max-width: 461px;
            box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
              0px 0.5px 2px rgba(96, 97, 112, 0.16);
            border-radius: 8px;

            .searchInput {
              width: 100%;
              box-shadow: none;
            }
          }

          .filerButton {
            margin-left: 15px;
            background: $BGWHITE;
            box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
              0px 0.5px 2px rgba(96, 97, 112, 0.16);
            border-radius: 8px;

            display: flex;
            align-items: center;
            justify-content: center;

            .MuiButtonBase-root {
              min-width: inherit;
            }

            .filterIcon {
              width: 40px;
              height: 100%;
              padding: 0;
              font-size: $FONT22;
              color: $TXTPRIMARY_ONE;

              &:hover {
                background-color: transparent;
              }
            }
          }
        }

        .userManagementDatatable {
          overflow: hidden;
          margin-top: 17px;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          overflow-y: auto;
          box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
            0px 0.5px 2px rgba(96, 97, 112, 0.16);

          > div:last-child {
            z-index: 1;
          }

          .UserManagementtable {
            width: 100%;

            .tableHeader {
              background-color: $TXTPRIMARY_ONE;
              border-radius: 10px 10px 0px 0px;

              .tableHeaderRow {
                .tableHeaderColumn {
                  font-weight: 600;
                  font-size: $FONT14;
                  line-height: 20px;
                  color: $BGPRIMARY_TWO;
                  font-family: $OPENSANS;
                  border-bottom: none;
                  background-color: $BGPRIMARY_ONE;

                  span {
                    position: relative;
                    top: 2px;
                    margin-left: 5px;
                    cursor: pointer;
                  }

                  &.mobilesizeShow {
                    display: none;
                  }

                  &:nth-child(1) {
                    padding-left: 64px;
                    padding-right: 0px;
                  }

                  &:nth-child(2) {
                    padding-right: 0px;
                  }

                  &:nth-child(3) {
                    padding-right: 0px;
                  }

                  &:nth-child(4) {
                    padding-right: 0px;
                  }

                  &:nth-child(6) {
                    width: 380px;
                    text-align: center;
                  }
                }
              }
            }

            .tableBody {
              .tableBodyRow {
                background: $BGWHITE;
                position: relative;

                &:hover {
                  cursor: pointer;
                  background: $BGWHITE !important;
                  -webkit-box-shadow: 1px 0px 15px 10px rgba(84, 95, 122, 0.1) !important;
                  -moz-box-shadow: 1px 0px 15px 10px rgba(84, 95, 122, 0.1) !important;
                  -ms-box-shadow: 1px 0px 15px 10px rgba(84, 95, 122, 0.1) !important;
                  -o-box-shadow: 1px 0px 15px 10px rgba(84, 95, 122, 0.1) !important;
                  box-shadow: 1px 0px 15px 10px rgba(84, 95, 122, 0.1) !important;
                  z-index: 1;

                  .tableBodyColumn {
                    .userDataStatus {
                      .statusModel {
                        visibility: visible;
                      }
                    }
                  }
                }

                &.disableColor {

                  // opacity: 0.5;
                  // box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
                  //   0px 0.5px 2px rgba(96, 97, 112, 0.16);
                  td {

                    &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(3) {
                      * {
                        color: #9ca5af !important;
                      }
                    }

                    &:nth-child(2) {
                      color: #9ca5af;
                    }

                    &:nth-child(1) {
                      .userShortName {
                        color: $TXTWHITE !important;
                        background-color: #9ca5af !important;
                      }
                    }

                    &:nth-child(4) {
                      color: #9ca5af;
                    }
                  }
                }

                &:last-child {
                  .tableBodyColumn {
                    border-bottom: none;
                  }
                }

                .tableBodyColumn {
                  font-weight: 400;
                  font-size: $FONT14;
                  color: $TXTPRIMARY_ONE;
                  font-family: $OPENSANS;
                  padding: 10px 16px;

                  &:last-child {
                    // border-bottom: none;
                  }

                  &:not(:last-child) {
                    word-break: break-word;
                  }

                  .userDetailsMobileSizeShow {
                    display: none;
                  }

                  &:nth-child(1) {
                    padding-left: 16px;
                    padding-right: 0px;
                    width: 15.2%;
                  }

                  &:nth-child(2) {
                    padding-right: 0px;
                    width: 20%;
                  }

                  &:nth-child(3) {
                    padding-right: 0px;
                    width: 20%;
                  }

                  &:nth-child(4) {
                    padding-right: 0px;
                    width: 12%;
                  }

                  &:nth-child(5) {
                    padding-right: 30px;
                    // text-align: center;
                  }

                  .userDetails {
                    display: flex;
                    align-items: center;

                    .userShortName {
                      width: 32px;
                      height: 32px;
                      border-radius: 50%;
                      background-color: $TXTPRIMARY_ONE;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      font-weight: 400;
                      font-size: $FONT12;
                      color: $BGWHITE;
                      font-family: $OPENSANS;
                      margin-right: 15px;
                      text-transform: uppercase;
                      flex: 1;
                    }

                    .userName {
                      font-weight: 400;
                      font-size: $FONT14;
                      color: $TXTPRIMARY_ONE;
                      word-break: break-word;
                      flex: 4;
                    }
                  }

                  .userDataStatus {
                    display: flex;
                    align-items: baseline;
                    justify-content: space-between;
                    align-content: stretch;

                    .userDataStatusChipActive {
                      display: flex;
                      align-items: center;

                      .subcreibeStatus {
                        background-color: $BGSECONDARY;
                        color: $TXTPRIMARY_ONE;
                        font-weight: 400;
                        font-size: $FONT12;
                        padding: 4px 8px;
                        border-radius: 8px;
                        font-family: $OPENSANS;

                        &.hideSubcreibe {
                          visibility: hidden;
                        }
                      }

                      .chip {
                        font-weight: 400;
                        font-size: $FONT12;
                        color: $TXTPRIMARY_ONE;
                        font-family: $OPENSANS;
                        border: 1px solid $BORDER_SECONDARY;
                        margin: 0 0px 0 71px;
                        padding: 0px 11px;
                        height: auto;

                        .MuiChip-label {
                          padding: 0px;
                        }

                        &.inactive {
                          color: $TXTOFFWHITE;
                          border-color: $BORDER_GREY;
                        }
                      }
                    }

                    .statusModel {
                      visibility: hidden;

                      .deleteBtn {
                        font-weight: 400;
                        font-size: $FONT14;
                        color: $TXTRED;
                        font-family: $OPENSANS;
                        text-transform: capitalize;
                        padding: 0;
                        min-width: inherit;
                        line-height: inherit;
                        letter-spacing: inherit;
                        border-radius: none;
                        margin-right: 16px;
                      }
                    }
                  }
                }
              }
            }
          }

          div:last-child {
            z-index: 1;
          }
        }
      }
    }
  }
}

.filterMenu {
  .MuiPaper-root.MuiMenu-paper {
    max-width: 256px;
    width: 100%;
    height: auto;
    background: $BGLIGHTGREY;
    border-radius: 8px;
    box-shadow: 0px 24px 48px rgba(84, 95, 122, 0.1);
    overflow: hidden;

    .MuiList-root {
      padding: 0px 0px 0px 0px;

      .filterMenuItem {
        padding: 0px;

        .filterCardBoxModel {
          width: 100%;

          .filterDateSection {
            padding: 19px 16px 15px 16px;

            .title {
              font-family: $OPENSANS;
              font-weight: 400;
              font-size: $FONT14;
              line-height: 21px;
              color: $TXTPRIMARY_ONE;
              margin-bottom: 9px;
            }

            .filterRadioButtonRow {
              width: 100%;
              display: flex;
              justify-content: flex-start;
              flex-direction: row;
              flex-wrap: wrap;
              gap: 8px;

              .RadioButton {
                width: fit-content;

                input[type="radio"] {
                  + label {
                    color: $TXTPRIMARY_ONE;
                    font-size: $FONT12;
                    font-weight: 400;
                    line-height: 18px;
                    padding: 9px 15px;
                    width: inherit;
                    font-family: $OPENSANS;
                  }

                  &:checked {
                    + label {
                      color: $TXTWHITE;
                    }
                  }
                }
              }
            }
          }

          .filterDateSectionButtonGroup {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 16px 16px 16px 16px;

            // border-top: 1px solid #3B4D61;
            .customLoadingBtnMain {
              // width: 180px;

              .submitButton {
                background: $BGPRIMARY_ONE;
                border-radius: 5px;
                margin: 0px 0px 0px 0px;
                color: $BGPRIMARY_TWO;
                padding: 10px 10px;
                width: 108px;
              }

              .clear {
                color: $TXTPRIMARY_ONE;
                width: 108px;
                text-transform: capitalize;
                font-family: $OPENSANS;
                font-style: normal;
                font-weight: 400;
                font-size: $FONT12;
              }
            }
          }

          hr {
            border: 1px solid rgba(59, 77, 97, 0.2);
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1281px) and (max-width: 1440px) {
  .userManageMent {
    .userManagermentWrapper {
      .userManagementCountainer {
        max-width: 1200px;
      }
    }
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
  .userManageMent {
    .userManagermentWrapper {
      .userManagementCountainer {
        .customSearchCardRow {
          .userManagementDatatable {
            .UserManagementtable {
              .tableHeader {
                .tableHeaderRow {
                  .tableHeaderColumn {
                    &:nth-child(1) {
                      padding-left: 34px;
                    }

                    &:nth-child(5) {
                      width: 220px;
                    }

                    &:nth-child(6) {
                      width: 350px;
                    }
                  }
                }
              }

              .tableBodyRow {
                .tableBodyColumn {
                  .userDataStatus {
                    .userDataStatusChipActive {
                      .chip {
                        margin: 0 0px 0 50px !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .userManageMent {
    .userManagermentWrapper {
      .userManagementCountainer {
        padding: 0px 20px;

        .customSearchCardRow {
          .userManagementDatatable {
            .UserManagementtable {
              .tableHeader {
                .tableHeaderRow {
                  vertical-align: top;

                  .tableHeaderColumn {
                    &:nth-child(5) {
                      // padding: 0px;
                      padding-right: 0px;
                      padding-left: 8px;
                      border-bottom: none;
                    }

                    &:nth-child(6) {
                      text-align: center;
                      padding-left: 8px;
                      width: 200px;
                      border-bottom: none;
                    }

                    &.mobilesizeShow {
                      display: table-cell;
                      border-bottom: none;
                    }
                  }

                  .mobilesizehide {
                    display: none;
                  }

                  .mobilesizeShow {
                    display: table-cell;
                    padding-left: 63px;
                  }
                }
              }

              .tableBody {
                .tableBodyRow {
                  .tableBodyColumn {
                    &:nth-child(4) {
                      padding-left: 8px;
                      width: 17%;
                    }

                    &:nth-child(1) {
                      text-align: center;
                      padding-left: 8px;
                      width: 39%;
                    }

                    &:last-child {
                      padding-right: 43px;
                    }

                    .mobilesizehide {
                      display: none;
                    }

                    .userDetailsMobileSizeShow {
                      display: table-cell;
                      max-width: 300px;

                      .userDetailsMbilesizeRow {
                        display: flex;

                        .userShortName {
                          width: 31px;
                          height: 31px;
                          border-radius: 50%;
                          background: $BGPRIMARY_ONE;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          font-size: $FONT12;
                          font-family: $OPENSANS;
                          color: $BGWHITE;
                          font-weight: 400;
                          text-transform: uppercase;
                        }

                        .userDetailsMbilesizeRowInner {
                          padding-left: 15px;
                          text-align: left;

                          .title {
                            font-weight: 400;
                            font-size: $FONT14;
                            color: $TXTPRIMARY_ONE;
                            font-family: $OPENSANS;
                          }

                          .userciliconeCode {
                            display: flex;
                            align-items: center;

                            .titlKey {
                              font-weight: 400;
                              font-size: $FONT14;
                              color: $TXTPRIMARY_ONE;
                              font-family: $OPENSANS;
                            }

                            .titleValue {
                              font-weight: 400;
                              font-size: $FONT14;
                              color: $TXTPRIMARY_ONE;
                              font-family: $OPENSANS;
                            }
                          }
                        }
                      }
                    }

                    &.mobilesizehide {
                      display: none;
                    }

                    .userDataStatus {
                      .userDataStatusChipActive {
                        display: flex;
                        align-items: center;

                        .subcreibeStatus {
                          font-size: $FONT12;
                          font-family: $OPENSANS;
                          word-break: keep-all;
                        }

                        .chip {
                          font-size: $FONT12;
                          font-family: $OPENSANS;
                          height: 19px;
                          margin: 0 20px 0 20px;
                        }
                      }

                      .statusModel {
                        display: flex;
                        align-items: center;
                        visibility: visible;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {
  .userManageMent {
    .userManagermentWrapper {
      .userManagementCountainer {
        padding: 0px 20px;
      }
    }
  }
}

@media only screen and (min-width: 1281px) and (max-width: 1440px) {}