@import "../../assets//scss/variables.scss";

.allFilterEnabled {
  opacity: 0.5;
  pointer-events: none;
}

.allFilterDisabled {
  color: rgb(255, 85, 85);
  text-decoration: underline;
}

.manageDeliveriesWrap {
  .dateSection {
    justify-content: space-between;
    color: $TXTPRIMARY_ONE;
    align-items: center;

    .dateTitle {
      font-weight: 600;
      font-family: $OPENSANS;
      font-size: $FONT16;
    }

    .rightWrap {
      align-items: center;
      position: relative;

      .react-datepicker-wrapper {
        width: auto;
      }

      .calText {
        font-weight: 400;
        font-size: 14px;
      }

      button.icon-calender {
        font-size: $FONT22;
        font-weight: bold;
        box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
          0px 0.5px 2px rgba(96, 97, 112, 0.16);
        color: $TXTPRIMARY_ONE;
        border-radius: 8px;
        background-color: $BGWHITE;
        display: inline-block;
        width: 40px;
        padding: 10px;
        text-align: center;
        margin-left: 14px;
        border: none;
        cursor: pointer;
      }
    }
  }

  .customCalendar {
    background: $BGPRIMARY_ONE;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    position: absolute;
    right: 0;
    top: 44px;
    z-index: 111;

    .react-datepicker {
      background: transparent;
      border: none;
    }

    .applyBtn {
      float: right;
      right: 20px;
      bottom: 20px;
      margin-top: 14px;
      background: $BGPRIMARY_TWO;
      border-radius: 6px;
      font-size: $FONT12;
      font-weight: 700;
      font-family: $OPENSANS;
      color: $TXTPRIMARY_ONE;
      text-transform: inherit;

      &:hover {
        background-color: $BGSECONDARY;
      }
    }
  }

  .dateSlider {
    margin-top: 24px;

    .slick-slider {
      max-height: 94px;

      .slick-list {
        margin: 0 41px;

        .dateBoxWrap {
          background: #ffffff;
          border: 1px solid #f1f1f1;
          border-radius: 10px;
          margin-right: 3px;
          padding: 10px 16px;
          border: 1px solid transparent;

          &.active {
            border-color: $BORDER_PRIM1;
          }

          cursor: pointer;

          * {
            font-weight: 600;
            font-size: 16px;
            font-family: "Opensans";
          }

          .date {
            color: $TXTPRIMARY_ONE;
          }

          .orders {
            color: $TXTOFFWHITE;
          }
        }

        .slick-slide {
          padding: 0 1.5px;

          &.slick-current {
            .mainWrap {
              &.active {
                .dateBoxWrap {
                  // border-color: #3B4D61;
                }
              }
            }
          }
        }
      }

      button.slick-arrow {
        background: $BGWHITE;
        border: 1px solid $BORDER_PRIMARY;
        border-radius: 10px;
        z-index: 1;
        height: 70px;
        width: 38px;
        text-align: center;

        &:before {
          color: black;
          font-family: "icomoon";
          z-index: 1;
          position: relative;
          opacity: 1;
        }

        &.slick-prev {
          left: 0;

          &:before {
            content: "\e914";
          }
        }

        &.slick-next {
          right: 0;

          &:before {
            content: "\e915";
          }
        }
      }
    }
  }

  .deliveriesBottom {
    margin-top: 24px;

    .postalWrap {
      width: calc(21.8% - 16px);
      display: inline-block;
      margin-right: 16px;
      vertical-align: top;
      color: $TXTPRIMARY_ONE;

      .postalTitle {
        margin-bottom: 16px;
        font-weight: 600;
        line-height: 40px;
      }

      .leftScrollStyle {
        overflow-y: auto;
        padding-right: 4px;

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: rgba(0, 0, 0, 0.2);
        }
      }

      .postCodeWrap {
        background: $BGWHITE;
        border: 1px solid $BORDER_PRIMARY;
        border-radius: 10px;
        padding: 3.5px 16px;
        align-items: center;
        position: relative;
        cursor: pointer;
        margin-top: 1px;

        i {
          color: $TXTOFFWHITE;
          font-size: 37px;
          margin-right: 15px;
        }

        .textWrap {
          font-size: $FONT14;
          color: $TXTOFFWHITE;
          line-height: 1.5;

          p {
            color: $TXTPRIMARY_ONE;
            font-weight: 600;
            font-size: $FONT14;
          }
        }

        span {
          position: absolute;
          right: 6px;
          font-size: 25px;
          color: $TXTPRIMARY_ONE;
        }

        &.active {
          background-color: $BGPRIMARY_ONE;

          * {
            color: $TXTWHITE !important;
          }
        }
      }
    }

    .orderDataWrap {
      width: 78.2%;
      display: inline-block;
      vertical-align: top;

      .innerContainer {
        justify-content: flex-end;

        .dropDownWrap {
          max-width: 280px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          &>span {
            font-size: $FONT14;
          }

          .MuiFormControl-root.customSelect {
            .MuiPaper-elevation.MuiPaper-rounded {
              padding: 5px 0;
            }

            .MuiSelect-select.MuiSelect-outlined {
              padding: 8px 16px;
            }
          }
        }
      }

      form {
        max-width: 224px;
        width: 100%;

        .customSelect {
          margin-bottom: 0;
          height: 40px;
        }

        .MuiFormControl-root.customSelect {
          .icon-chevron-down {
            top: 5px;
            right: 12px
          }

          .MuiInputBase-formControl.placeholder {
            .MuiSelect-select {
              height: 40px;
              line-height: 24px;
              padding: 12px 16px;
            }
          }
        }
      }

      .deliveriesDataTable {
        margin-top: 16px;
        border-radius: 16px 16px 0 0;

        div:last-child {
          z-index: 1;
        }

        table {
          thead {
            tr {
              z-index: 2 !important;
            }
          }

          tr {
            position: relative;
            background: $BGWHITE;
            cursor: pointer;

            &:hover {
              box-shadow: 0px 12px 24px rgba(84, 95, 122, 0.06);
              z-index: 1;
            }

            td {
              position: relative;
              border-bottom: 0;
              padding: 16px 0 16px 10px;

              .status {
                &.red {
                  color: $TXTRED;
                }

                &.green {
                  color: $TXTGREEN2;
                }

                &.blue {
                  color: #0D8EF8;
                }
              }

              .icon-angle-right-b {
                position: absolute;
                right: 10px;
                font-size: 22px;
                top: 50%;
                transform: translateY(-50%);
              }

              .copyHover {
                opacity: 0;
                margin-left: 10px;
                cursor: pointer;
                display: inline-flex;
                align-items: center;
                position: relative;
                top: 3px;

                .icon {
                  font-size: 20px;
                }

                span:last-child {
                  font-size: $FONT14;
                  font-weight: 600;
                  margin-left: 4px;
                }
              }

              &:hover {
                .copyHover {
                  opacity: 1;
                }
              }

              &:first-child {
                width: 105px
              }

              &:nth-child(2) {
                width: 22%;

                .username {
                  font-weight: 600;
                  letter-spacing: normal;
                }

                span.desktopWrap {
                  align-items: center;
                }
              }

              &:last-child {
                width: 16.29%;
              }
            }

            &.delivered {
              td {
                &:not(:last-child) {
                  .stateChange {
                    opacity: 0.5;
                  }
                }
              }
            }
          }

          thead {
            tr {
              cursor: default;
              box-shadow: none !important;

              th {
                background-color: $BGPRIMARY_TWO;
                font-weight: 600;
                padding: 14px 0 14px 10px;
                border-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}

.nameImg.MuiTypography-root {
  width: 32px;
  height: 32px;
  display: inline-block;
  color: $TXTWHITE;
  background: $TXTPRIMARY_ONE;
  border-radius: 50px;
  line-height: 34px;
  font-size: 12px !important;
  margin-right: 8px;
  text-align: center;
}

.addressDetails {
  margin-top: 24px;
  background: $BGWHITE;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
    0px 0.5px 2px rgba(96, 97, 112, 0.16);
  border-radius: 10px;
  padding: 8px 17px;

  .title {
    font-weight: 600;
    margin-bottom: 8px;
  }

  * {
    font-size: $FONT14;
    font-family: $OPENSANS;
    line-height: 1.5;
    color: $TXTPRIMARY_ONE;
  }
}

.orderStatusPopup.MuiBox-root {
  max-width: 900px;
  height: 850px;
  color: $TXTPRIMARY_ONE;
  box-shadow: 0px 24px 48px rgba(84, 95, 122, 0.1);

  * {
    font-family: $OPENSANS;
    letter-spacing: normal;
  }

  .userName {
    font-weight: 600;
  }

  .orderDetails {
    justify-content: space-between;
    align-items: center;
    background: $BGWHITE;
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
      0px 0.5px 2px rgba(96, 97, 112, 0.16);
    border-radius: 10px;
    padding: 12px 20px;
    margin-top: 14px;

    * {
      font-size: $FONT14;
    }

    .orderId {
      font-weight: 600;
    }

    .orderDate {
      span {
        font-weight: 600;
      }
    }

    .orderType {
      font-size: $FONT12;
      line-height: 22px;
    }

    .orderStatus {
      span {
        font-weight: 600;
      }
    }

    .orderSubscription {
      border: 1px solid $BORDER_SECONDARY;
      border-radius: 10px;
      padding: 0 11px;
    }
  }

  .addressDetails {
    margin-top: 14px;
    padding: 12px;
    height: calc(100% - 14px);
  }

  .red {
    color: $TXTRED;
  }

  .green {
    color: $TXTGREEN2;
  }

  .blue {
    color: #0D8EF8;
  }

  .bold {
    font-weight: 700;
  }

  .userDataTable {
    margin-top: 16px;
    border-radius: 16px;

    table {
      background-color: $BGWHITE;

      thead {
        tr {
          th {
            background-color: $BGPRIMARY_ONE;
            color: $TXTWHITE;
            padding: 13.5px 16px;
          }
        }
      }

      tr {
        td {
          padding: 5.5px 16px;

          &:first-child {
            width: 38.25%;

            .productName {
              display: flex;
              align-items: center;

              img {
                margin-right: 8px;
              }

              .brushColor {
                width: 16px;
                height: 16px;
                border-radius: 2px;
                margin-right: 5px;
              }

              .productSize {
                font-weight: 400;
                font-size: $FONT12;
                font-family: $OPENSANS;
                color: $BGPRIMARY_ONE;
                padding-left: 5px;
                line-height: 18px;
              }
            }
          }

          &:nth-child(2) {
            width: 20.58%;
          }

          &:nth-child(3) {
            width: 20.58%;
          }

          &:last-child {
            width: 20.58%;
          }
        }

        &:last-child {
          td {
            border: none;
          }
        }
      }
    }
  }

  .updateStatus {
    margin-top: 16px;
    background: $BGWHITE;
    border-radius: 10px;
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
      0px 0.5px 2px rgba(96, 97, 112, 0.16);
    padding: 12px 12px 42px;

    .rc-slider {
      max-width: 500px;
      margin-top: 12px;
      margin-left: 10px;

      // pointer-events: none;
      .rc-slider-rail {
        height: 7.66px;
        background-color: #d9d9d9;
        border-radius: 7px;
      }

      .rc-slider-dot {
        height: 5.25px;
        width: 2px;
        top: 8px;
        border-radius: 0;
        background-color: $BGGREY;
        border: none;

        &:first-child {
          transform: translateX(200%) !important;
        }

        &:last-child {
          transform: translateX(-300%) !important;
        }
      }

      .rc-slider-mark {
        top: 21px;

        span {
          &:first-child {
            transform: translate(0) !important;
          }

          &:nth-child(2) {}

          &:last-child {
            transform: translate(-100%) !important;
          }
        }
      }

      &.rc-slider-disabled {
        background-color: transparent;
      }

      .rc-slider-handle {
        margin-top: -9px;
        background: $BGWHITE;
        border: 2px solid $BORDER_PRIM1;
        width: 24px;
        height: 24px;
        opacity: 1;

        &.rc-slider-handle-1 {
          display: none;
        }

        &.rc-slider-handle-dragging {
          border-color: $BGGREEN;
          box-shadow: 0 0 0 2px $BGGREEN;
        }
      }

      .rc-slider-track {
        position: absolute;
        height: 7.66px;
        background-color: $BGGREEN;
        border-radius: 7px;
      }
    }
  }
}

table {
  .desktopItem {
    display: table-cell;
  }

  .responsiveItem {
    display: none;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .manageDeliveriesWrap {
    .deliveriesBottom {
      .orderDataWrap {
        .deliveriesDataTable {
          table {
            tr {
              td {
                &:first-child {
                  width: 92px;
                }

                &:nth-child(2) {
                  width: 25%;
                }

                &:last-child {
                  width: 15.34%;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  table {
    .desktopItem {
      display: none;
    }

    .responsiveItem {
      display: table-cell;
    }
  }

  .manageDeliveriesWrap {
    .deliveriesBottom {
      .postalWrap {
        width: calc(25.8% - 16px);
      }

      .orderDataWrap {
        width: 74.2%;

        .deliveriesDataTable {
          table {
            tr {
              td {
                &:nth-child(2) {
                  width: 74.36%;

                  &>span {
                    display: inline-block;
                    vertical-align: middle;

                    &:last-child {
                      width: calc(100% - 40px);
                    }
                  }
                }

                &:last-child {
                  width: 25.54%;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-height: 850px) {
  .orderStatusPopup.MuiBox-root {
    max-width: 750px;
    max-height: 650px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
}