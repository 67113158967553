@import "./assets/scss/variables.scss";

* {
  // font-family: $MONTSERRATREGULAR;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  // font-family: $MONTSERRATREGULAR;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

