@import "../../assets/scss/variables.scss";
.patientsEducationPage {
 
  .list1 {
    float: left;
    width: 100%;
  }
  .articleList {
    &.MuiBox-root {
      padding-left: 8px;
      padding-right: 8px;
      margin-top: 0px;
      .cardWrap {
        &.MuiPaper-root {
          margin: 0 4px 16px;
          width: calc(25% - 8px);
          .rightWrap {
            justify-content: unset;
          }
        }
      }
    }
  }
  .videoList {
    &.MuiBox-root {
      padding-left: 8px;
      padding-right: 8px;
      margin-top: 0px;
      .videoCard {
        &.MuiPaper-root {
          margin: 0 8px 16px;
          width: calc(25% - 16px);
        }
      }
    }
  }
  .categoryVideoList {
    &.MuiBox-root {
      padding-left: 8px;
      padding-right: 8px;
      margin-top: 0px;
      .cardWrap {
        &.MuiPaper-root {
          margin: 0 8px 16px;
          width: calc(25% - 16px);
        }
      }
    }
  }
}
.smallPopup {
  .MuiBox-root {
    p {
      max-width: 189px;
      margin: 0 auto;
      word-break: break-word;
    }
  }
}
.addArticle.MuiBox-root {
  max-width: 570px;
  .addArticleTop {
    gap: 25px;
    .MuiTextField-root {
      .MuiOutlinedInput-root {
        padding-left: 15px !important;
      }
    }
    .imagesWrap {
      margin-top: 0;
      .uploadedImg {
        width: 100%;
        img {
          height: 159px;
          width: 142px;
        }
      }
      .image {
        width: 142px;
        height: 159px;
      }
    }
    .articleTopRight {
      width: 100%;
    }
    .MuiFormControl-root {
      &.articleDrop {
        margin-top: 11px;
      }
    }
    .input_addbtn {
      .MuiTextField-root {
        .MuiOutlinedInput-root {
          padding-left: 0 !important;
        }
      }
    }
  }
  .customTextField {
    .position {
      .requiredField {
        display: block;
      }
    }
    .valid {
      .requiredField {
        display: none;
      }
    }
    .hasValue {
      label.requiredField {
        display: none;
      }
    }
  }
  .btnWrap {
    margin-top: 8px;
  }
  .customEditor.rdw-editor-main {
    border-radius: 0 0 10px 10px;
    background-color: $BGWHITE;
    height: 120px;
    border: 1px solid $BORDER_SECONDARY;
    border-top: 0;
    padding: 0 10px;
    .public-DraftStyleDefault-ltr {
      margin-top: 10px;
    }
  }
  .rdw-editor-toolbar.customToolbar {
    border-radius: 10px 10px 0 0;
    border-color: $BORDER_SECONDARY;
    background-color: $BGPRIMARY_TWO;
    border-bottom: 0;
    padding: 5px 12px;
    margin: 0;

    & > div {
      margin-bottom: 0;
      & > div {
        border: none;
        background-color: transparent;
        img {
          width: 12px;
        }
      }
      &:first-child {
        & > div {
          &:first-child {
            img {
              width: 10px;
            }
          }
        }
      }
    }
    .rdw-history-wrapper {
      margin-left: auto;
    }
    .rdw-inline-wrapper,
    .rdw-list-wrapper {
      margin-right: 10px;
    }
  }
  .customTextField.hasError {
    .customToolbar,
    .customEditor.rdw-editor-main {
      border-color: $BORDER_RED;
    }
  }
}
.customEditorWrap {
  .errorMsg {
    display: none;
  }
  &.hasError {
    .errorMsg {
      display: block;
    }
  }
}
.btn-centerpopup{
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  .customLoadingBtnMain{
    button {
      margin: 0px !important;
      width: 100% !important;
      min-width: 100% !important;
      max-width: 100%;
      display: block;
  }
  }
  button {
    margin: 0px !important;
    width: 100% !important;
    min-width: 100% !important;
    max-width: 100%;
    display: block;
}

}

.rdw-image-modal,
.rdw-emoji-modal,
.rdw-link-modal {
  background: $BGWHITE !important;
}
.MuiBox-root.articlePin {
  max-width: 500px;
  h5 {
    margin-bottom: 8px;
  }
  p {
    font-size: $FONT14;
    color: $TXTPRIMARY_ONE;
    max-width: 410px;
    // word-break: break-all;
  }
  .articleNamePop {
    color: $TXTGREEN2;
  }
  .articleList.popup {
    margin-top: 24px;
    max-height: 350px;
    // overflow-y: auto;
    .cardWrap.pinCard {
      width: 100%;
      border: 1px solid $BORDER_SECONDARY;
      margin-bottom: 8px;
      .rightWrap {
        padding: 0;
        .cardContent {
          padding-top: 0;
          .cardTop {
            background-color: $BGWHITE;
            padding: 7px 16px;
            .title {
              max-width: 93%;
              min-height: auto;
            }
          }
        }
      }
    }
  }
  .SearchWrap {
    margin-top: 24px;
  }
}
.videoPopup {
  .videoWrap {
    padding: 0;
    width: auto;
    line-height: 0;
  }
}
.addVideoModal {
  .addModalWrap.MuiBox-root {
    max-width: 395px;
  }
  .MuiFormControl-root.customSelect {
    margin-bottom: 24px;
  }
  .customTextField {
    .hasValue {
      label.requiredField {
        display: none;
      }
    }
    .inputControlStyle {
      margin-bottom: 24px;
    }
    .position {
      .requiredField {
        display: block;
      }
    }
    .valid {
      .requiredField {
        display: none;
      }
    }
  }
  .addCategory {
    .position {
      .requiredField {
        display: none !important;
      }
    }
  }
}
main.customTextField.hasError {
  label.requiredField {
    display: none;
  }
}

.addVideo {
  .MuiTextField-root {
    .MuiOutlinedInput-root {
      padding-left: 15px !important;
    }
  }
  .btnWrap{
    .customLoadingBtnMain{
      width: 50%;
      .applyBtn{
        width: 100%;
      }
    }
  }
}
.MuiBox-root.categoryVideoList {
  justify-content: flex-start;
  .cardWrap.MuiPaper-root {
    width: calc(25% - 16px);
  }
}
@media (min-width: 1280px) and (max-width: 1440px) {
  .articleList.MuiBox-root {
    .cardWrap {
      .rightWrap {
        .cardBottom {
          div {
            &:nth-child(2) {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .MuiBox-root.categoryVideoList {
    .cardWrap.MuiPaper-root {
      width: calc(33.333% - 16px);
    }
  }
  .patientsEducationPage {
    .articleList.MuiBox-root {
      .cardWrap.MuiPaper-root {
        width: calc(33.333% - 8px);
      }
    }
  }
  .articleList.MuiBox-root {
    .cardWrap {
      .rightWrap {
        .rightSide {
          margin-left: 6px;
        }
      }
    }
  }
  .patientsEducationPage {
    .videoList.MuiBox-root {
      .videoCard.MuiPaper-root {
        width: calc(33.333% - 16px);
      }
    }
  }
  .patientsEducationPage {
    .categoryVideoList.MuiBox-root {
      .cardWrap.MuiPaper-root {
        margin: 0 8px 16px;
        width: calc(33.333% - 16px);
      }
    }
  }
}

@media (max-width: 1023px) {
  .MuiBox-root.categoryVideoList {
    .cardWrap.MuiPaper-root {
      width: calc(49% - 16px);
    }
  }
  .patientsEducationPage {
    .articleList.MuiBox-root {
      .cardWrap.MuiPaper-root {
        width: calc(50% - 8px);
      }
    }
  }
  .patientsEducationPage {
    .videoList.MuiBox-root {
      .videoCard.MuiPaper-root {
        width: calc(50% - 16px);
      }
    }
  }
  .patientsEducationPage {
    .categoryVideoList.MuiBox-root {
      .cardWrap.MuiPaper-root {
        margin: 0 8px 16px;
        width: calc(50% - 16px);
      }
    }
  }
  .tabWrap {
    & + .MuiTabPanel-root {
      overflow-y: scroll;
      height: 78vh;
    }
  }
}
