@import "../../assets/scss/variables.scss";

svg.searchIcon {
  color: $TXTSECONDARY;
}
.MuiBox-root {
  .SearchWrap {
    max-width: 461px;
    width: 100% !important;
    margin: 0;
    position: relative;
    .icon-search2 {
      z-index: 1;
      color: $TXTSECONDARY;
      padding: 0px 16px;
      position: absolute;
      line-height: 39px;
    }
    .inputWrap {
      .searchInput {
        padding-left: 41px;
        padding-top: 9px;
        padding-bottom: 10px;
        padding-right: 35px;
        box-shadow: 0px 2px 3px rgba(40, 41, 61, 0.08);
        border-radius: 8px;
        outline: none;
        border: none;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #6C6C6C;
      }
      input {
        padding-left: calc(1em + 26px);
        padding-right: 40px;
        width: 100%;
      }
      input::-webkit-input-placeholder {
        /* Edge */
        color: $TXTOFFWHITE !important;
        font-size: $FONT14;
        font-family: $OPENSANS;
        opacity: 1;
      }

      input:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $TXTOFFWHITE !important;
        opacity: 1;
        font-size: $FONT14;
        font-family: $OPENSANS;
      }

      input::placeholder {
        color: $TXTOFFWHITE !important;
        opacity: 1;
        font-size: $FONT14;
        font-family: $OPENSANS;
      }
    }
    .clearIcon {
      position: absolute;
      font-size: 14px;
      color: #707070;
      cursor: pointer;
      background: transparent;
      border: none;
      top: 13px;
      right: 17px;
    }
  }
}
