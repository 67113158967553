@import "../../../assets/scss/variables.scss";

.MuiAppBar-colorPrimary {
  background-color: $BGPRIMARY_ONE !important;
}

header {
  box-shadow: none !important;
  .MuiToolbar-root {
    padding: 0 40px;
    display: flex;
    border: none;
    & > div {
      display: inline-block;
      vertical-align: middle;
    }
    .menu-list-wrap.MuiBox-root {
      line-height: 0;
      margin-left: 5.05%;
    }
  }
  .active{
    a{
      background-color: $BGSECONDARY;
      color: #3B4D61 !important;
    }
  }
  // .header-menu{
  // }
  a {
    &:link,
    &:visited {
      font-family: $OPENSANS;
      display: inline-block;
      font-size: $FONT16;
      line-height: 72px;
      padding: 0 22px;
      color: $TXTWHITE;
    }
    &:hover,
    &:focus,
    &.active {
      background-color: $BGSECONDARY;
      color: $TXTPRIMARY_ONE;
    }
  }
  .menu-right {
    margin-left: auto;
    svg {
      stroke-width: 2;
      color: $TXTWHITE;
    }
  }
  button.toggleBtn {
    display: none;
    visibility: hidden;
    color: $TXTWHITE;
  }
  .logo-wrap {
    line-height: 0;
  }
}

.menu-right.MuiBox-root {
  & > button {
    line-height: 72px;
    border-radius: 0;
    border-right: 1px solid rgba(241, 241, 241, 0.2);
    padding: 0 22px;
    &:first-child {
      border-left: 1px solid rgba(241, 241, 241, 0.2);
    }
  }
  span.MuiBadge-badge {
    transform: scale(0.7) translate(30%, 15%);
  }
  button {
    font-size: $FONT26;
    color: $TXTWHITE;
  }
  & > button {
    display: inline-block;
    &:last-child {
      span.MuiBadge-badge {
        right: 4px;
        bottom: 3px;
      }
    }
  }
}
.errorMsg:empty {
  display: none;
}
.MuiMenu-list {
  li {
    background-color: transparent !important ;
  }
}
.menu-right {
  float: right;
}
.shipMenu {
  .MuiPaper-root.MuiMenu-paper {
    max-width: 280px;
    width: 100%;
    box-shadow: 0px 24px 48px rgba(84, 95, 122, 0.1);
  }
  ul {
    li {
      flex-direction: column;
      padding: 8px 16px;
      form {
        width: 100%;
      }
      .customTextField {
        .position {
          .MuiOutlinedInput-root {
            &:before {
              content: "£";
              left: 2px;
              top: -1px;
              position: relative;
              font-weight: 600;
              font-size: $FONT16;
              color: $TXTPRIMARY_ONE;
            }
          }
        }
        .MuiOutlinedInput-root {
          border-radius: 5px;
          box-shadow: none;
          background-color: transparent;
          height: 48px;
          .MuiInputBase-input {
            color: $TXTPRIMARY_ONE;
            font-weight: 600;
            padding-left: 3px;
            font-size: $FONT16;
          }
        }
        &.hasError {
          .MuiOutlinedInput-notchedOutline {
            border-color: rgba(0, 0, 0, 0.23) !important;
          }
        }
        .inputControlStyle {
          margin-bottom: 24px;
        }
      }
      .textfieldLabel {
        font-weight: 600;
        font-size: $FONT16;
        color: $TXTPRIMARY_ONE;
        line-height: 1.5;
        margin-bottom: 10px;
        font-family: $OPENSANS;
      }
      .btnWrap {
        display: flex;
        gap: 16px;
        & > * {
          width: 50%;
        }
        button {
          border: 1px solid $BORDER_GREY;
          border-radius: 8px;
          color: $TXTDARK;
          text-transform: initial;
          font-weight: 600;
          font-family: $OPENSANS;
          box-shadow: none;
          height: 48px;
          background-color: $BGPRIMARY_ONE;
          &.cancelBtn {
            background-color: transparent;
          }
        }
        .customLoadingBtnMain {
          button {
            color: $TXTWHITE;
            &.Mui-disabled {
              background-color: $BGGREY;
            }
          }
        }
      }
    }
  }
}
.profile {
  .MuiPaper-root.MuiMenu-paper {
    max-width: 375px;
    width: 100%;
    // height: 505px;
    background-color: $BGLIGHTGREY;
    padding: 17px 20px 17px 20px;
    box-shadow: 0px 24px 48px rgba(84, 95, 122, 0.1);
    .MuiList-root {
      padding: 0px;
      .adminProfileCard {
        .adminProfileUplaodCadr {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .sectionTitle {
            width: 100%;
            display: flex;
            align-items: flex-start;
            margin-bottom: 37px;
            .title {
              font-weight: 600;
              font-size: $FONT16;
              line-height: 24px;
              color: $TXTPRIMARY_ONE;
              font-family: $OPENSANS;
            }
          }
          .fileUploadSection {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .fileUploadShowSection {
              width: 100px;
              height: 100px;
              border-radius: 50%;
              object-fit: cover;
              background: $BGPRIMARY_ONE;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;

              .userImage {
                width: 100px;
                height: 100px;
                border-radius: 50%;
                object-fit: cover;
              }
              .userShortName {
                width: 100px;
                height: 100px;
                border-radius: 50%;
                background: $BGPRIMARY_ONE;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 400;
                font-size: 32px;
                color: $TXTWHITE;
                text-transform: uppercase;
              }
            }
            .fileUpload {
              margin-top: 4px;
              color: $TXTBLUE;
              font-size: $FONT12;
              font-family: $OPENSANS;
              line-height: 18px;
              cursor: pointer;

              .dropzoneCustom {
                span {
                  font-size: $FONT12;
                  font-family: $OPENSANS;
                  color: $TXTBLUE;
                }
              }
            }
            button.removeBtn {
              background: none;
              font-size: $FONT12;
              font-family: $OPENSANS;
              border: none;
              color: $TXTRED;
              cursor: pointer;
              text-transform: capitalize;
              padding: 0;
            }
          }
        }
        .profileFormGroupField {
          margin-top: 25px;
          overflow: hidden;
          .formGroupField {
            &.fomGroupFieldName {
              .customTextField {
                .position {
                  .inputControlStyle {
                    border-radius: 8px 8px 0 0;
                    margin-bottom: 1px;
                  }
                  .updateText {
                    .MuiInputAdornment-positionEnd {
                      button.MuiIconButton-root {
                        color: $TXTGREEN2;
                      }
                    }
                  }
                  .editText {
                  }
                }
              }
            }
            .customTextField {
              .position {
                .inputControlStyle {
                  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
                    0px 0.5px 2px rgba(96, 97, 112, 0.16);
                  background: $BGWHITE;
                  margin: 0;
                  .MuiInputBase-root {
                    box-shadow: none;
                    background-color: transparent;
                    padding: 0 20px;
                    .icon {
                      color: $TXTOFFWHITE;
                      font-size: 24px;
                    }
                    .MuiInputBase-input {
                      color: $TXTPRIMARY_ONE;
                      height: 53px;
                    }
                    .MuiInputAdornment-positionStart {
                      margin-right: 16px;
                    }
                    .MuiInputAdornment-positionEnd {
                      margin-right: 7px;
                      .MuiIconButton-root {
                        color: $TXTRED;
                        font-weight: 400;
                        font-size: 12px;
                        padding: 0px 0px 0px 0px;
                      }
                    }
                    &.MuiOutlinedInput-root {
                      ::-webkit-input-placeholder {
                        color: $TXTPRIMARY_ONE;
                        opacity: 1;
                      }
                      :-ms-input-placeholder {
                        color: $TXTPRIMARY_ONE;
                        opacity: 1;
                      }
                      ::placeholder {
                        color: $TXTPRIMARY_ONE;
                        opacity: 1;
                      }
                    }
                    .MuiOutlinedInput-notchedOutline {
                      padding: 0px;
                    }
                  }
                }
              }
            }
            &:nth-child(2) {
              .customTextField {
                .position {
                  .inputControlStyle {
                    border-radius: 0 0 8px 8px;
                  }
                }
              }
            }
          }
        }
        .profileFormGroupButton {
          margin-top: 16px;
          border-radius: 8px 8px 8px 8px;
          background: $BGWHITE;

          overflow: hidden;
          .formGroupButton {
            &.fomGroupButtonOne {
            }
            .customLoadingBtnMain {
              .MuiLoadingButton-root {
                background-color: transparent;
                box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
                  0px 0.5px 2px rgba(96, 97, 112, 0.2);
                padding: 13px 20px;
                color: $TXTPRIMARY_ONE;
                font-size: $FONT14;
                line-height: 21px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                text-transform: initial;
                border-radius: 8px 8px 0 0;
                letter-spacing: normal;
                margin-bottom: 1px;
                font-family: $OPENSANS;
                .MuiButton-startIcon {
                  margin-right: 18px;
                  color: $TXTOFFWHITE;
                  i {
                    font-size: 24px;
                  }
                }
              }
            }
            &:last-child {
              .customLoadingBtnMain {
                .MuiLoadingButton-root {
                  border-radius: 0 0 8px 8px;
                }
              }
            }
          }
        }
      }
      .addProfileChangePassword {
        .sectionTitle {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .title {
            font-weight: 600;
            font-size: $FONT16;
            line-height: 24px;
            color: $TXTPRIMARY_ONE;
          }
        }
        .changePasswordFormFeild {
          margin-top: 16px;
          .formGroupField {
            .customTextField {
              .position {
                .inputControlStyle {
                  .MuiInputBase-root {
                    background: $BGWHITE;
                    border: 1px solid $BORDER_SECONDARY;
                    box-shadow: none;
                    .MuiInputBase-input {
                      color: $TXTPRIMARY_ONE;
                      height: 48px;
                    }
                  }
                }
                &.valid {
                  .requiredField {
                    display: none;
                  }
                }
                .requiredField {
                  display: block;
                }
              }
              .hasValue {
                .position {
                  .requiredField {
                    display: none;
                  }
                }
              }
              &.hasError {
                .position {
                  .inputControlStyle {
                    .MuiInputBase-root {
                      border-color: $BORDER_RED;
                    }
                  }
                }
              }
              .errorMsg {
                &.show {
                  .red {
                    color: $BORDER_RED;
                  }
                  .green {
                  }
                }
              }
            }
          }
          .formGroupButton {
            margin-top: 19px;
            .customLoadingBtnMain {
              .customLoadingBtn {
                padding: 12px 16px;
                color: $TXTWHITE;
                font-weight: 800;
                text-transform: capitalize;
                border-radius: 10px;
                font-family: $OPENSANS;
                background-color: $BGPRIMARY_ONE;
                &.Mui-disabled {
                  background-color: $BGGREY;
                  &:hover {
                    background-color: $BGGREY;
                  }
                }
                &.outlineCancelBtn {
                  margin-top: 8px;
                  color: $TXTPRIMARY_ONE;
                  background-color: transparent !important;
                  border-color: $BORDER_PRIM1;
                }
                &:hover {
                  background-color: $BGPRIMARY_ONE;
                }
              }
            }
          }
        }
      }
    }
  }
}
.notification {
  * {
    font-family: $OPENSANS !important;
  }
  .MuiPaper-root.MuiMenu-paper {
    max-width: 375px;
    width: 100%;
    height: auto;
    background: $BGLIGHTGREY;
    padding: 20px 16px 20px 16px;
    border-radius: 8px;
    box-shadow: 0px 24px 48px rgba(84, 95, 122, 0.1);
    .MuiList-root {
      padding: 0px;
      .notificationCard {
        .sectionTitle {
          .title {
            font-weight: 600;
            font-size: $FONT16;
            line-height: 24px;
            color: $TXTPRIMARY_ONE;
          }
        }
        .customSearchBox {
          width: 100%;
          margin-top: 15px;
          margin-bottom: 17px;
          .searchBoxDiv {
            width: 100%;
            .searchInput {
              width: 100%;
              padding: 10px 40px 10px 16px;
            }
            .icon {
              display: none;
            }
          }
        }
        .notitficationCardRow {
          .notificationColumn {
            background: $BGWHITE;
            filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.05));
            box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
              0px 0.5px 2px rgba(96, 97, 112, 0.2);
            border-radius: 8px;
            padding: 8px 16px 8px 8px;
            .notificationheaderSection {
              .starIcon {
                width: 5px;
                height: 5px;
                background: #707070;
                border-radius: 50%;
                margin-right: 4px;
              }
              .title {
                font-weight: 400;
                font-size: $FONT16;
                line-height: 24px;
                color: $TXTPRIMARY_ONE;
              }
            }
            .descreption {
              font-weight: 400;
              font-size: $FONT14;
              line-height: 21px;
              color: $TXTPRIMARY_ONE;
              margin: 0px;
              padding: 0px 0px 0px 8px;
            }
            .dateAndTimeSection {
              margin: 8px 0px 0px 0px;
              .dateAndTime {
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                color: $TXTPRIMARY_ONE;
              }
            }
          }
        }
      }
    }
  }
}
.MuiInputBase-root-MuiOutlinedInput-root:hover {
  .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23) !important;
  }
}
div.MuiDrawer-paper {
  display: none;
}
@media (max-width: 1670px) {
  header.MuiPaper-root {
    a {
      &:link,
      &:visited {
        padding: 0 9px;
      }
    }
  }
}
@media (min-width: 1440px) and (max-width: 1520px) {
  header {
    .MuiToolbar-root {
      padding: 0 15px;
      a {
        &:link,
        &:visited {
          padding: 0 13px;
        }
      }
      .menu-list-wrap.MuiBox-root {
        margin-left: 10px;
      }
    }
    .menu-right.MuiBox-root {
      & > button {
        padding: 0 19px;
      }
    }
  }
}
@media (max-width: 1439px) {
  header.MuiPaper-root {
    max-height: 144px;
    a:link,
    a:visited {
      padding: 0 32px;
    }
    .MuiToolbar-root {
      padding: 0;
      flex-wrap: wrap;
      .logo-wrap {
        padding-left: 15px;
      }
      .menu-right {
        padding-right: 15px;
      }
      .menu-list-wrap.MuiBox-root {
        order: 2;
        width: 100%;
        margin-left: 0;
        display: flex;
        justify-content: space-between;
        background-color: $BGDARK;
      }
    }
  }
}
@media (max-width: 1290px) {
  header.MuiPaper-root {
    a {
      &:link,
      &:visited {
        padding: 0 15px;
      }
    }
  }
}
@media (max-width: 1023px) {
  header.MuiPaper-root {
    .MuiToolbar-root {
      button.toggleBtn {
        display: block;
        visibility: visible;
        padding-left: 20px;
        margin-right: 10px;
        line-height: 0;
        margin-left: 0;
      }
      .menu-right {
        margin-left: auto;
      }
      .menu-list-wrap.MuiBox-root {
        display: none;
      }
    }
  }
  .MuiDrawer-root.sidebar {
    & > div.MuiDrawer-paper {
      background-color: $BGPRIMARY_ONE;
      display: flex;
      a {
        line-height: 72px;
        font-size: $FONT16;
        font-family: $OPENSANS;
        color: $TXTPRIMARY_TWO;
        padding: 0 22px;
        &:hover,
        &:focus,
        &.active {
          color: $TXTPRIMARY_ONE;
          background-color: $BGSECONDARY;
        }
      }
    }
    & > div {
      & > div {
        justify-content: flex-start;
        button {
          color: $TXTWHITE;
          left: 10px;
        }
      }
    }
  }
}
