@import "../../assets/scss/variables.scss";

.orderDetails {
  width: 100%;
  background-color: #f9f9fb;

  .orderDetailsContainer {
    max-width: 1295px;
    width: 100%;
    margin: 0 auto;

    h1.mainTitle {
      i {
        display: inline-block;
        float: right;
        font-size: 24px;
        cursor: pointer;
      }
    }

    .particulerUserDetails {
      background: $BGWHITE;
      box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
        0px 0.5px 2px rgba(96, 97, 112, 0.16);
      border-radius: 8px;
      padding: 8px 19px;

      * {
        font-family: $OPENSANS;
      }

      .particuleruserDetailsColumn {
        display: flex;
        align-items: center;

        .userSHortName {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          background-color: $BGPRIMARY_ONE;
          display: flex;
          align-items: center;
          justify-content: center;
          font-style: normal;
          font-weight: 600;
          font-size: $FONT16;
          color: $TXTWHITE;
          font-family: $OPENSANS;
        }

        .fullName {
          font-weight: 400;
          font-size: $FONT14;
          line-height: 21px;
          font-family: $OPENSANS;
          color: $TXTPRIMARY_ONE;
          margin-left: 16px;
        }

        .orderDate {
          font-weight: 400;
          font-size: $FONT14;
          line-height: 21px;
          color: $TXTPRIMARY_ONE;
        }

        .orderId {
          font-weight: 400;
          font-size: $FONT14;
          line-height: 21px;
          color: $TXTPRIMARY_ONE;
        }

        .orderTime {
          font-weight: 600;
          font-size: $FONT14;
          line-height: 21px;
          color: $TXTPRIMARY_ONE;
        }

        .orderIdNumber {
          font-weight: 600;
          font-size: $FONT14;
          line-height: 21px;
          color: $TXTPRIMARY_ONE;
        }

        .divided {
          border-left: 1px solid $TXTPRIMARY_ONE;
          height: 30%;
          margin-left: 26px;
          margin-right: 27px;
        }
      }
    }

    .orderDetailsDataTable {
      margin-top: 24px;
      border-radius: 8px;

      table {
        thead {
          tr {
            cursor: default;

            th.MuiTableCell-root {
              background-color: $BGPRIMARY_ONE;
              color: $TXTWHITE;
              padding: 14px 12px;

              * {
                color: $TXTWHITE;
              }
            }
          }
        }

        tr {
          cursor: pointer;

          * {
            font-family: $OPENSANS;
            font-size: $FONT16;
            color: $TXTPRIMARY_ONE;
          }

          .MuiTableCell-root {
            border-bottom: 2px solid #eeeff3;
            padding: 7.5px 12px;
            background-color: $BGWHITE;
            min-width: 110px;
          }

          .productDetails {
            flex-direction: column;
            margin-left: 16px;

            .productName {
              font-weight: 600;
            }

            .productSubscriptionId {
              font-size: $FONT12;
              margin-top: 6px;
            }

            .mainBox {
              display: flex;
              align-items: center;

              .brushColor {
                width: 16px;
                height: 16px;
                border-radius: 2px;
                margin-right: 5px;
              }

              .productSize {
                font-weight: 400;
                font-size: $FONT12;
                font-family: $OPENSANS;
                color: $BGPRIMARY_ONE;
                padding-left: 5px;
                line-height: 18px;
              }
            }
          }

          &.totalCountRows {
            .MuiTableCell-root {
              border-top: 3px solid #eff0f3;
            }
          }

          .totalPaid {
            font-size: $FONT20;
            font-weight: 600;
            font-family: $OPENSANS;
          }

          .detailsTop {
            .imageWrap {
              line-height: 0;
            }

            .productDetails {
              flex-direction: column;
              color: $TXTPRIMARY_ONE;
              margin-left: 15px;

              .mainBox {
                display: flex;
                align-items: center;

                .brushColor {
                  width: 16px;
                  height: 16px;
                  border-radius: 2px;
                  margin-right: 5px;
                }
              }

              .productName {
                font-weight: 600;
              }

              .productSubscriptionId {
                font-size: $FONT12;
                margin-top: 6px;
              }
            }
          }

          &:last-child {
            .MuiTableCell-root {
              border-bottom: none;
            }
          }
        }
      }
    }
  }

  .orderType {
    font-size: $FONT12 !important;
    font-family: $OPENSANS;
    border-radius: 10px;
    padding: 0 11px;
    border: 1px solid $BORDER_SECONDARY;
    color: $TXTPRIMARY_ONE;
    word-break: keep-all;

    &.subscribed {
      background: $BGSECONDARY;
    }
  }
}

.addressDetails {
  margin-top: 24px;
  background: $BGWHITE;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
    0px 0.5px 2px rgba(96, 97, 112, 0.16);
  border-radius: 10px;
  padding: 8px 17px;

  .title {
    font-weight: 600;
    margin-bottom: 8px;
  }

  * {
    font-size: $FONT14;
    font-family: $OPENSANS;
    line-height: 1.5;
    color: $TXTPRIMARY_ONE;
  }
}

.orderDetailsPopup.MuiBox-root {
  max-width: 768px;
  height: 850px;
  color: $TXTPRIMARY_ONE;
  box-shadow: 0px 24px 48px rgba(84, 95, 122, 0.1);

  * {
    font-family: $OPENSANS;
    letter-spacing: normal;
  }

  .productDetails {
    margin-left: 16px;

    .mainBox {
      display: flex;
      align-items: center;

      .brushColor {
        width: 16px;
        height: 16px;
        border-radius: 2px;
        margin-right: 5px;
      }
    }

    &>* {
      margin-bottom: 5px;
      font-family: $OPENSANS;
      font-size: $FONT14;
    }

    .pName {
      font-weight: 700;
    }

    .pDigits {
      span {
        font-weight: 600;
        margin-right: 25px;
        font-size: $FONT14;
      }
    }
  }

  .orderDetails {
    justify-content: space-between;
    background: $BGWHITE;
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
      0px 0.5px 2px rgba(96, 97, 112, 0.16);
    border-radius: 10px;
    padding: 12px;
    margin-top: 14px;

    * {
      font-size: $FONT14;
    }

    .orderId {
      font-weight: 600;
    }

    .orderDate {
      span {
        font-weight: 600;
      }
    }

    .orderType {
      font-size: $FONT12;
      line-height: 22px;
    }

    .orderStatus {
      span {
        font-weight: 600;
      }
    }

    .orderSubscription {
      border: 1px solid $BORDER_SECONDARY;
      border-radius: 10px;
      padding: 0 11px;
    }
  }

  .addressDetails {
    margin-top: 14px;
    padding: 12px;
    height: calc(100% - 14px);
  }

  .totalNumbers {
    background: $BGWHITE;
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
      0px 0.5px 2px rgba(96, 97, 112, 0.16);
    border-radius: 10px;
    padding: 12px;
    margin-top: 14px;

    div {
      display: flex;
      justify-content: space-between;
      font-size: $FONT14;

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      span {
        font-size: $FONT14;
        font-weight: 600;
      }
    }
  }

  .summary {
    margin-top: 22px;

    .MuiTypography-subtitle {
      font-weight: 600;
      color: $TXTPRIMARY_ONE;
      margin-bottom: 14px;
      font-size: $FONT14;
    }

    .statusWrap {
      position: relative;

      &:before {
        content: "";
        position: absolute;
        left: 9px;
        top: 0;
        width: 1px;
        height: 100%;
        border-left: 1px dashed $BORDER_PRIM1;
      }

      .commonWrap {
        padding-left: 32px;
        margin-bottom: 22px;
        position: relative;

        .icon {
          font-size: 18px;
          position: absolute;
          left: 0;
          background-color: $BGPRIMARY_TWO;
          line-height: 1;
          top: 2px;
        }

        .productName {
          margin-bottom: 3px;
        }

        * {
          font-size: $FONT14;
        }

        .statusDetails {
          justify-content: space-between;

          .right {
            text-align: right;
          }

          .delivery {
            font-weight: 600;
          }
        }

        .details2 {
          span {
            margin-right: 10px;
          }
        }

        &.updateLetest {
          .productName {
            font-weight: 600;
          }
        }
      }
    }
  }

  .mainBox {
    display: flex;
    align-items: center;

    .brushColor {
      width: 16px;
      height: 16px;
      border-radius: 2px;
      margin-right: 5px;
    }

    .productSize {
      font-weight: 400;
      font-size: $FONT12;
      font-family: $OPENSANS;
      color: $BGPRIMARY_ONE;
      padding-left: 5px;
      line-height: 18px;
    }
  }

  .red {
    color: $TXTRED;
  }

  .green {
    color: $TXTGREEN2;
  }

  .bold {
    font-weight: 700;
  }
}

.desktopItem {
  display: table-cell;
}

.responsiveItem {
  display: none;
}

@media (max-height: 850px) {
  .orderDetailsPopup.MuiBox-root {
    max-width: 750px;
    max-height: 650px;
    overflow-y: auto;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px) {

  .orderDetails table th:nth-child(2),
  .orderDetails table td:nth-child(2),
  .orderDetails table th:nth-child(4),
  .orderDetails table td:nth-child(4) {
    display: none;
  }

  .desktopItem {
    display: none;
  }

  .responsiveItem {
    display: table-cell;
  }

  .orderDetailsPopup.MuiBox-root {
    max-width: 750px;
    max-height: 650px;
    overflow-y: auto;
  }

  .orderDetails {
    .orderDetailsContainer {
      .orderDetailsDataTable {
        table {
          tr {
            .MuiTableCell-root:nth-child(3) {
              *:not(:first-child) {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}