@import "../../assets/scss/variables.scss";
.articleList.MuiBox-root {
  justify-content: unset;
  .cardWrap {
    width: calc(25% - 16px);
    .rightWrap {
      padding: 8px;
      .cardContent {
        padding-top: 0;
        flex: 1;
        background: $BGPRIMARY_TWO;
        border-radius: 8px;
        .title {
          font-family: $OPENSANS;
          font-weight: 600;
          font-size: $FONT14;
          line-height: 1.5;
          color: $TXTPRIMARY_ONE;
          max-width: 91%;
          white-space: unset;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-word;
          min-height: 63px;
        }
        .cardTop {
          
          padding: 7px;
          position: relative;
          .icon-drag {
            color: $TXTOFFWHITE;
            position: absolute;
            right: 7px;
            bottom: 6px;
            cursor: grab;
          }
        }
      }
      .rightSide {
        padding-top: 6px;
        &.hid {
          visibility: hidden;
        }
      }
      .cardBottom {
        padding-bottom: 0;
        padding-top: 8px;
        align-items: flex-start;
        flex: 1;
        .smallTitle {
          font-family: $OPENSANS;
          font-weight: 400;
          font-size: $FONT10;
          line-height: 15px;
          margin-bottom: 4px;
          color: $TXTPRIMARY_ONE;
        }
        .category {
          margin-bottom: 0;
        }
        & > div:first-child {
          & > div {
            max-width: 67px;
            overflow: hidden;
            text-overflow: ellipsis;  
          }
        }
        & > div {
          & > div {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: $TXTPRIMARY_ONE;
            font-family: $OPENSANS;
            word-break: break-word;
          }
        }
        .statusText {
          border-radius: 10px;
          padding: 0 11px;
        }
        .active {
          .statusText {
            border: 1px solid #e8bcb1;
          }
        }
        .inactive {
          .statusText {
            border: 1px solid $TXTOFFWHITE;
            color: $TXTOFFWHITE;
          }
        }
      }
      .pin-status {
        border-radius: 10px;
        margin-right: 7px;
        padding: 0px 10px;
        font-size: 10px;
        line-height: 18px;
        display: inline-block;
        border: 1px solid $TXTOFFWHITE;
        margin-top: 4px;
        font-family: $OPENSANS;
        cursor: pointer;
        &:empty {
          visibility: hidden;
        }
        &.disabled{
          pointer-events: none;
          opacity: 0.3;
        }
      }
      .icon-pin {
        position: absolute;
        right: 7px;
        top: 2px;
      }
    }
    &.notPinned {
      .icon-pin {
        display: none;
      }
      .icon-drag {
        display: none;
      }
      &.disabled{
        pointer-events: none;
        opacity: 0.5;
      }
    }
    &.pinned {
      .pin-status {
        background: #e8bcb1;
        border: none;
      }
    }
  }
}
@media (max-width: 1024px) {
  .cardWrap.MuiPaper-root {
    .rightSide {
      visibility: visible;
    }
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .articleList.MuiBox-root {
    .cardWrap {
      width: 32.3%;
    }
  }
}
@media (max-width: 1023px) {
  .articleList.MuiBox-root {
    .cardWrap {
      width: 49%;
    }
  }
}
